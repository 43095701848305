import styled from "styled-components";

import theme from "../../../common/global/theme";
import fonts from "../../../utils/fonts";
import {device} from "../../../utils/mediaQueries";

export const PageContainer = styled.div`
  font-family: ${fonts.lato.paragraph.fontFamily};
  max-width: 1150px;
  margin: 40px auto;
  padding: 20px;
  text-align: left;
`;

export const SpacedBlock = styled.div`
  width: 100%;
  margin-bottom: 40px;

  @media ${device.mobile} {
    margin-bottom: 24px;
  }
`;

export const Title = styled.h2`
  ${fonts.lato.headingH3};
  color: ${theme.colors.black};
  margin-bottom: 40px;
  text-align: left;

  @media ${device.mobile} {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const SectionTitle = styled.h3`
  ${fonts.lato.headingH3};
  color: ${theme.colors.black};
  margin: 60px 0 30px;
  text-align: left;

  @media ${device.mobile} {
    font-size: 18px;
  }
`;

export const Paragraph = styled.p`
  ${fonts.lato.paragraph};
  margin-bottom: 16px;
  color: ${theme.colors.gray};
  text-align: justify;
  width: 100%;

  @media ${device.mobile} {
    font-size: 14px;
  }
`;

export const TwoColumnSection = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
  margin-bottom: 40px;
  width: 100%;

  @media ${device.tablet} {
    flex-direction: column;
  }

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  flex: 1;
  text-align: left;
`;

export const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;

  img {
    width: 100%;
    max-width: 500px;
    height: auto;
    display: block;
  }

  @media ${device.tablet} {
    justify-content: center;
    img {
      max-width: 400px;
    }
  }

  @media ${device.mobile} {
    justify-content: center;
    img {
      max-width: 300px;
    }
  }
`;

export const List = styled.ul`
  list-style-type: none;
  padding-left: 0;
  text-align: left;
`;

export const ListItem = styled.li`
  ${fonts.lato.paragraph};
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  &::before {
    content: "";
    width: 10px;
    height: 10px;
    background-color: #2776bb;
    display: inline-block;
    margin-right: 8px;
    border-radius: 2px;
  }
`;

export const Word = styled.h2`
  font-family: ${fonts.lato.paragraph.fontFamily};
  ${fonts.lato.paragraph};
  text-align: center;
  color: ${theme.colors.blue};
  margin: 0 auto 10px;

  @media ${device.tablet} {
    text-align: center;
  }
`;

export const FullWidthSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  > div {
    max-width: 1150px;
    width: 100%;
    padding: 0 20px;
  }
`;

export const CenteredSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: left;

  > div {
    width: 100%;
    max-width: 1150px;
    padding: 0 20px;
  }
`;
