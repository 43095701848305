import {GlobalOutlined as Global} from "@ant-design/icons";

import ImageInfo from "../../../assets/images/solutions/ImageSolutions.svg";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import PhotoGallery from "../../../components/photoGallery";
import ProjectInfo from "../projectInfo";
import * as S from "./styles";

const plantData = [
  {
    icon: (
      <Global
        style={{
          color: "#00ADEF",
        }}
      />
    ),
    title: "Industry",
    description: "PVC Coating/ Flooring",
  },
  {
    icon: (
      <Global
        style={{
          color: "#00ADEF",
        }}
      />
    ),
    title: "Activity",
    description: "Plasticizer fumes/ Odor Control",
  },
  {
    icon: (
      <Global
        style={{
          color: "#00ADEF",
        }}
      />
    ),
    title: "Technology",
    description: "Mist Eliminators",
  },
  {
    icon: (
      <Global
        style={{
          color: "#00ADEF",
        }}
      />
    ),
    title: "Flow",
    description: "Am3/hr",
  },
  {
    icon: (
      <Global
        style={{
          color: "#00ADEF",
        }}
      />
    ),
    title: "Temperature",
    description: "°C",
  },
];

const photos = [
  {src: ImageInfo, alt: "Photo 1", description: "Photo description"},
  {src: ImageInfo, alt: "Photo 2", description: "Photo description1"},
  {src: ImageInfo, alt: "Photo 3", description: "Photo description2"},
  {src: ImageInfo, alt: "Photo 1", description: "Photo descriptio3"},
  {src: ImageInfo, alt: "Photo 2", description: "Photo description4"},
  {src: ImageInfo, alt: "Photo 3", description: "Photo description5"},
];

function OrganicPackage() {
  return (
    <>
      <Header />
      <S.PageContainer>
        <S.MainHeading>Organic Package Plant</S.MainHeading>
        <S.SubHeading>Heerhugowaard, Netherlands - February, 2023</S.SubHeading>
        <ProjectInfo data={plantData} />
        <S.Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris rhoncus enim a leo
          feugiat, non interdum sem condimentum. Quisque blandit lorem quis felis pellentesque
          luctus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac
          turpis egestas. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere
          cubilia curae; Proin lacinia nunc nisl, blandit fermentum quam rutrum sit amet. Fusce ut
          porta libero. Maecenas scelerisque eros id sem gravida faucibus. Orci varius natoque
          penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse at nisl
          dignissim, dictum erat semper, feugiat nulla. Mauris semper leo at nulla cursus venenatis.
          Pellentesque fermentum, lectus sed egestas consequat, lectus dui iaculis orci, ac
          imperdiet ligula mauris et turpis. In congue iaculis pretium. In ac diam aliquet,
          vestibulum mi non, finibus orci.
        </S.Paragraph>
        <S.Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris rhoncus enim a leo
          feugiat, non interdum sem condimentum. Quisque blandit lorem quis felis pellentesque
          luctus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac
          turpis egestas. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere
          cubilia curae; Proin lacinia nunc nisl, blandit fermentum quam rutrum sit amet. Fusce ut
          porta libero. Maecenas scelerisque eros id sem gravida faucibus. Orci varius natoque
          penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse at nisl
          dignissim, dictum erat semper, feugiat nulla. Mauris semper leo at nulla cursus venenatis.
          Pellentesque fermentum, lectus sed egestas consequat, lectus dui iaculis orci, ac
          imperdiet ligula mauris et turpis. In congue iaculis pretium. In ac diam aliquet,
          vestibulum mi non, finibus orci.
        </S.Paragraph>
        <S.SectionTitle>Photos</S.SectionTitle>
        <PhotoGallery images={photos} />
      </S.PageContainer>
      <Footer />
    </>
  );
}

export default OrganicPackage;
