import React, {useEffect, useContext} from "react";

import Header from "../../../components/header";
import Questions from "../../../components/questions";
import CompanyCarousel from "../../../components/carousel";
import GetInTouch from "../../../components/getInTouch";
/*import Servicess from "../../../components/Servicess";*/
import Footer from "../../../components/footer";
import carbonImage from "../../../assets/images/solutions/ImageSolutions.svg";
import InstallationServicess from "../../../components/Servicess/installationServices";
import Breadcrumbs from "../../../components/breadcrumbs";
import * as S from "./styles";
import {GlobalContext} from "../../../context/global/globalContext";

const questionsData = [
  {
    question: "What technologies do you use for emission reduction?",
    answer:
      "<span style=\"font-family: 'Lato', sans-serif\">We utilize a range of technologies including advanced scrubbers, MECS® mist eliminators, mesh pads, activated carbon filters.</span>",
  },
  {
    question: "How can emission reduction solutions benefit my business?",
    answer:
      "<span style=\"font-family: 'Lato', sans-serif\">Our emission control systems help you meet environmental regulations, reduce pollutant output, and improve operational efficiency.</span>",
  },
];

function LaboratoryTesting() {
  const {setBreadcrumbs} = useContext(GlobalContext);

  useEffect(() => {
    setBreadcrumbs([
      {label: "Home", path: "/"},
      {label: "Services", path: "/services"},
      {label: "Laboratory Testing", path: "/services/laboratory-testing"},
    ]);
  }, [setBreadcrumbs]);

  return (
    <>
      <Header />
      <S.PageContainer>
        <Breadcrumbs />

        <S.SectionTitle>Laboratory testing</S.SectionTitle>
        <S.Paragraph>
          At Ecosystems Europe BV, we operate our own in-house laboratory dedicated to
          customer-specific testing. Our experts collaborate closely with your process engineers to
          understand the challenge on-site and simulate it under controlled conditions—delivering
          practical, data-driven insights for real-world solutions. We specialize in activated
          carbon testing and absorption testing by gas scrubbers, assessing performance under
          different gas conditions such as VOC, H₂S, SO₂, and NH₃. For more complex analyses, we
          work with international partner labs to provide in-depth chemical and structural testing
          when needed.
        </S.Paragraph>

        <S.TwoColumnSection>
          <S.Column>
            <S.SectionTitle>Applications</S.SectionTitle>
            <S.List>
              <S.ListItem>
                Adsorption capacity and breakthrough testing (VOC, H₂S, SO₂, NH₃)
              </S.ListItem>
              <S.ListItem>Carbon performance comparison across suppliers or batches</S.ListItem>
              <S.ListItem>H₂S, SO₂ and NH₃ removal efficiency validation</S.ListItem>
              <S.ListItem>Droplet separation and scrubber behavior analysis</S.ListItem>
              <S.ListItem>Pre-evaluation of carbon before field use</S.ListItem>
            </S.List>
          </S.Column>
          <S.ImageContainer>
            <img src={carbonImage} alt="Carbon Scrubber Equipment" />
          </S.ImageContainer>
        </S.TwoColumnSection>

        <S.SectionTitle>What we offer</S.SectionTitle>
        <S.Paragraph>
          We offer a full range of lab services from scrubber droplet separation studies to
          activated carbon performance tests. This includes capacity testing, moisture content
          determination, H₂S, SO₂,NH₃ and VOC efficiency testing —helping you choose the right
          material or setup for your application with confidence.
        </S.Paragraph>

        <S.CenteredSection>
          <InstallationServicess />
        </S.CenteredSection>

        <S.SpacedBlock>
          <Questions items={questionsData} />
        </S.SpacedBlock>

        <S.Word>Our Services</S.Word>
        <S.Title>
          We partner with industry leaders to create clean alternatives to <br />
          traditional industrial processes
        </S.Title>

        <S.FullWidthSection>
          <CompanyCarousel />
        </S.FullWidthSection>
        <S.FullWidthSection>
          <GetInTouch />
        </S.FullWidthSection>
      </S.PageContainer>
      <Footer />
    </>
  );
}

export default LaboratoryTesting;
