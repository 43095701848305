import {LinkedinOutlined as LinkedinIcon} from "@ant-design/icons";
import styled from "styled-components";

import {ReactComponent as EmailIcon} from "../../assets/images/IconEmail.svg";
import {ReactComponent as LanguageIcon} from "../../assets/images/IconLanguage.svg";
import {ReactComponent as PhoneIcon} from "../../assets/images/IconPhone.svg";
import {ReactComponent as LogoEcosystems} from "../../assets/images/LogoEco.svg";
import fonts from "../../utils/fonts";
import {device} from "../../utils/mediaQueries";

export const FooterContainer = styled.footer`
  background-color: ${({theme}) => theme.colors.secondaryBlack};
  color: ${({theme}) => theme.colors.white};
  padding: 40px;
  display: flex;
  flex-direction: column;
`;

export const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media ${device.tablet} {
    flex-wrap: wrap;
    justify-content: space-around;
  }

  @media ${device.mobile} {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 250px;

  @media ${device.mobile} {
    align-items: center;
    margin-bottom: 20px;
  }
`;

export const Logo = styled(LogoEcosystems)`
  width: 160px;
  height: auto;
  margin-bottom: 10px;
`;

export const Address = styled.p`
  ${fonts.roboto.paragraphLight}
  color: ${({theme}) => theme.colors.white};
`;

export const ContactInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  ${fonts.roboto.paragraph}
  color: ${({theme}) => theme.colors.white};
`;

export const IconEmail = styled(EmailIcon)`
  width: 16px;
  height: 16px;
  color: ${({theme}) => theme.colors.white};
`;

export const IconPhone = styled(PhoneIcon)`
  width: 16px;
  height: 16px;
  color: ${({theme}) => theme.colors.white};
`;

export const IconLinkedin = styled(LinkedinIcon)`
  width: 16px;
  height: 16px;
  color: ${({theme}) => theme.colors.white};
`;

export const FooterLink = styled.a`
  color: ${({theme}) => theme.colors.white};
  ${fonts.roboto.paragraph}
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  cursor: pointer;
`;

export const StyledLink = styled.a`
  text-decoration: none;
  color: ${({theme}) => theme.colors.white};

  &:hover {
    text-decoration: underline;
  }
`;

export const Separator = styled.hr`
  border: none;
  border-top: 1px solid ${({theme}) => theme.colors.white};
  margin: 20px 0;
`;

export const FooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media ${device.mobile} {
    flex-direction: column;
    gap: 10px;
  }
`;

export const Text = styled.p`
  ${fonts.roboto.paragraph}
  color: ${({theme}) => theme.colors.white};
`;

export const Links = styled.div`
  ${fonts.roboto.paragraph}
  display: flex;
  gap: 20px;
  align-items: center;

  @media ${device.mobile} {
    flex-direction: column;
    gap: 10px;
  }
`;

export const LanguageSelector = styled(LanguageIcon)`
  width: 44px;
  height: 44px;
  cursor: pointer;

  path {
    fill: ${({theme}) => theme.colors.white};
  }
`;
