import React from "react";

import carbonImage from "../../../assets/images/solutions/ImageSolutions.svg";
import CardTechnology from "../../../components/cardTechnology";
import CompanyCarousel from "../../../components/carousel";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import * as S from "./styles";
import GetInTouch from "../../../components/getInTouch";
import Questions from "../../../components/questions";

function MeshPads() {
  const dewData = [
    {
      id: "Filter",
      title: "Activated Wet Scrubbers",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris rhoncus enim a leo feugiat, non interdum sem condimentum. Quisque blandit lorem quis felis pellentesque luctus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Proin lacinia nunc nisl, blandit fermentum quam rutrum sit amet. Fusce ut porta libero. Maecenas scelerisque eros id sem gravida faucibus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse at nisl dignissim, dictum erat semper, feugiat nulla. Mauris semper leo at nulla cursus venenatis. ",
    },
    {
      id: "Filter",
      title: "Dry Scrubbers",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris rhoncus enim a leo feugiat, non interdum sem condimentum. Quisque blandit lorem quis felis pellentesque luctus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Proin lacinia nunc nisl, blandit fermentum quam rutrum sit amet. Fusce ut porta libero. Maecenas scelerisque eros id sem gravida faucibus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse at nisl dignissim, dictum erat semper, feugiat nulla. Mauris semper leo at nulla cursus venenatis. ",
    },
    {
      id: "Filter",
      title: "Eletroctatic Precipitators",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris rhoncus enim a leo feugiat, non interdum sem condimentum. Quisque blandit lorem quis felis pellentesque luctus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Proin lacinia nunc nisl, blandit fermentum quam rutrum sit amet. Fusce ut porta libero. Maecenas scelerisque eros id sem gravida faucibus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse at nisl dignissim, dictum erat semper, feugiat nulla. Mauris semper leo at nulla cursus venenatis. ",
    },
  ];

  const questionsData = [
    {
      question: "What technologies do you use for emission reduction?",
      answer:
        "<span style=\"font-family: 'Lato', sans-serif\">We utilize a range of technologies including advanced scrubbers, MECS® mist eliminators, mesh pads, activated carbon filters.</span>",
    },
    {
      question: "How can emission reduction solutions benefit my business?",
      answer:
        "<span style=\"font-family: 'Lato', sans-serif\">Our emission control systems help you meet environmental regulations, reduce pollutant output, and improve operational efficiency.</span>",
    },
  ];

  return (
    <>
      <Header />
      <S.Container>
        <S.MainHeading>Mesh Pads</S.MainHeading>
        <S.Paragraph>
          Mesh pads—also known as demisters or droplet separators—are essential components used to
          remove fine liquid droplets from gas streams. Installed in gas scrubbers, distillation
          columns, or stripping towers, these units significantly improve separation efficiency and
          protect downstream equipment. With our wide range of material options and
          custom-engineered configurations, Ecosystems Europe delivers high-performance mesh pad
          solutions for challenging industrial conditions, including high temperatures and corrosive
          environments.
        </S.Paragraph>

        <S.TwoColumnSection>
          <S.Column>
            <S.SectionTitle>How Mesh Pads Work</S.SectionTitle>
            <S.Paragraph>
              Mesh pads operate by capturing droplets as they collide and impact the mesh structure.
              As the gas passes through the mesh, liquid droplets are forced to contact the surface
              of the fibers, where they coalesce and drain away under gravity.
              <br />
              <br />
              Depending on the process, mesh pads can be produced from a variety of materials such
              as:
              <ul>
                <li>Metals: Stainless steel 304, 316, Hastelloy, Alloy 20, Monel</li>
                <li>Plastics: PP, PE, PTFE (PVDF), Hostaflon</li>
                <li>
                  Fiberglass and PTFE fibers: Used for co-knitting to improve fine mist capture
                </li>
              </ul>
              <br />
              This design allows mesh pads to efficiently separate droplets as small as 0,5 microns,
              with extremely low pressure drop and minimal maintenance.
            </S.Paragraph>
          </S.Column>
          <S.ImageContainer>
            <img src={carbonImage} alt="Carbon Scrubber Equipment" />
          </S.ImageContainer>
        </S.TwoColumnSection>

        <S.Section>
          <S.SectionTitle>Installation</S.SectionTitle>
          <S.Paragraph>
            We design and deliver mesh pad demisters tailored to your process requirements. Whether
            integrated into new columns or retrofitted into existing systems, we ensure a perfect
            fit, optimal drainage design, and easy maintenance access. Available as drop-in units,
            welded designs, or modular sections, our mesh pads are engineered for quick and
            hassle-free installation in both new and existing setups.
          </S.Paragraph>
        </S.Section>

        <S.Section>
          <S.SectionTitle>Maintenance</S.SectionTitle>
          <S.Paragraph>
            Our mesh pad systems are robust and maintenance-friendly. Optional support grids ensure
            mechanical stability, and allow for quick removal and cleaning if required.
          </S.Paragraph>
        </S.Section>

        <S.Section>
          <S.SectionTitle>Certifications and Compliance</S.SectionTitle>
          <S.Paragraph>
            All mesh pads are manufactured to meet international standards and can be customized to
            comply with ATEX, FDA, or specific client requirements depending on the process and
            industry.
          </S.Paragraph>
        </S.Section>
        <CardTechnology gridList data={dewData} />
        <S.ContainerTitles>
          <S.MiniTitle>Our Partners</S.MiniTitle>
          <S.Title>
            We partner with industry leaders to create clean alternatives to traditional industrial
            processes
          </S.Title>
        </S.ContainerTitles>
        <Questions items={questionsData} />
        <CompanyCarousel />
        <GetInTouch />
      </S.Container>
      <Footer />
    </>
  );
}

export default MeshPads;
