import styled from "styled-components";

import theme from "../../../common/global/theme";
import fonts from "../../../utils/fonts";
import {device} from "../../../utils/mediaQueries";

export const PlantContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 40px;

  @media ${device.tablet} {
    justify-content: center;
  }

  @media ${device.mobile} {
    flex-direction: column;
    align-items: center;
  }
`;

export const PlantItem = styled.div`
  flex: 1;
  min-width: 200px;
  max-width: 250px;
  text-align: center;
  padding: 10px;
  background: ${theme.colors.white};

  @media ${device.mobile} {
    max-width: 100%;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 8px;
`;

export const Icon = styled.div`
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PlantTitle = styled.h4`
  ${fonts.lato.headingH5};
  color: ${theme.colors.black};
  margin-bottom: 4px;
`;

export const PlantDescription = styled.p`
  ${fonts.lato.paragraph};
  color: ${theme.colors.gray};
  display: flex;
  justify-content: start;
`;
