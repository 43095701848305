import styled from "styled-components";

import {ReactComponent as Email} from "../../assets/images/home/IconEmail.svg";
import {ReactComponent as Phone} from "../../assets/images/home/IconPhone.svg";
import theme from "../../common/global/theme";
import fonts from "../../utils/fonts";
import {device} from "../../utils/mediaQueries";

export const PageWrapper = styled.div`
  overflow-x: hidden;
`;

export const CompaniesSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: ${theme.colors.white};
  padding: 20px 0;
  margin-top: -40px;

  @media ${device.tablet} {
    margin-top: -20px;
  }

  @media ${device.mobile} {
    margin-top: -10px;
    padding: 15px 0;
  }
`;

export const CompaniesText = styled.p`
  ${fonts.lato.paragraph};
  color: ${theme.colors.black};
  text-align: center;

  @media ${device.mobile} {
    font-size: 16px;
  }
`;

export const TechnologiesSubtitle = styled.p`
  ${fonts.lato.paragraph};
  color: ${theme.colors.blue};
  text-align: center;
  margin-top: 20px;
`;

export const TechnologiesTitle = styled.h2`
  ${fonts.lato.headingH2};
  text-align: center;
  margin-bottom: 16px;
`;

export const TechnologiesIntroText = styled.p`
  ${fonts.roboto.headingRegular};
  text-align: center;
  padding: 20px;
  max-width: 1162px;
  margin: 0 auto;
`;

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  .highlight {
    color: ${({theme}) => theme.colors.blue};
    font-size: 14px;
    font-weight: 600;
  }

  h2 {
    ${fonts.lato.headingH2};
    color: ${({theme}) => theme.colors.black};
  }

  p {
    ${fonts.lato.paragraph};
    color: ${({theme}) => theme.colors.grayDark};
    margin-top: 4px;
  }

  @media ${device.mobile} {
    h2 {
      font-size: 20px;
    }
  }
`;

// ========== CONTATO ==========
export const ContactSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 75px;
  padding: 80px 64px;

  @media ${device.tablet} {
    flex-direction: column;
    align-items: center;
    padding: 48px 32px;
  }

  @media ${device.mobile} {
    flex-direction: column;
    align-items: center;
    padding: 32px 16px;
  }
`;

export const LeftContent = styled.div`
  flex: 1;
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ContactInfo = styled.div`
  display: flex;
  gap: 48px;
  flex-wrap: wrap;
  ${fonts.lato.small};
  margin-top: 8px;

  @media ${device.mobile} {
    flex-direction: column;
    gap: 12px;
  }
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const RightContent = styled.div`
  flex: 1;
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const MapWrapperWithBlock = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  border-radius: 12px;
  overflow: hidden;

  @media ${device.tablet} {
    height: 350px;
  }

  @media ${device.mobile} {
    height: 300px;
  }
`;

export const TextBlock = styled.div`
  .highlight {
    color: ${({theme}) => theme.colors.blue};
    ${fonts.lato.paragraph};
  }

  h2 {
    ${fonts.lato.headingH3};
    margin: 8px 0;
  }

  p {
    ${fonts.lato.small};
    color: ${({theme}) => theme.colors.grayDark};
  }

  @media ${device.mobile} {
    h2 {
      font-size: 20px;
    }
  }
`;

export const MapWrapper = styled.div`
  width: 100%;
  height: 400px;
  border-radius: 12px;
  overflow: hidden;
  z-index: 1;

  @media ${device.tablet} {
    height: 350px;
  }

  @media ${device.mobile} {
    height: 300px;
  }
`;

export const IconPhone = styled(Phone)`
  width: 30px;
  height: 30px;
`;

export const IconEmail = styled(Email)`
  width: 30px;
  height: 30px;
`;
