import styled from "styled-components";

import theme from "../../common/global/theme";
import fonts from "../../utils/fonts";
import {device} from "../../utils/mediaQueries";

export const Container = styled.div`
  padding: 40px 20px;
  background-color: ${theme.colors.white};
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: ${({gridList}) => (gridList ? "1fr" : "repeat(4, 1fr)")};
  gap: 20px;
  max-width: 1200px;
  margin: auto;

  @media ${device.tablet} {
    grid-template-columns: ${({gridList}) => (gridList ? "1fr" : "repeat(2, 1fr)")};
  }

  @media ${device.mobile} {
    grid-template-columns: 1fr;
  }
`;

export const CardGrid = styled.div`
  background: ${theme.colors.white};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.02);
  }
`;

export const ContentGrid = styled.div`
  padding: 20px;
`;

export const KnowMore = styled.a`
  ${fonts.roboto.paragraph};
  color: ${theme.colors.blue};
  font-weight: bold;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const CardList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${theme.colors.white};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  padding: 20px;

  &:hover {
    transform: scale(1.02);
  }

  @media ${device.mobile} {
    flex-direction: column;
    text-align: center;
  }
`;

export const ImageWrapper = styled.div`
  width: 30%;
  overflow: hidden;

  @media ${device.mobile} {
    width: 100%;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

export const ContentList = styled.div`
  padding: 20px;
  flex: 1;
`;

export const Title = styled.h3`
  ${fonts.roboto.headingH4};
  color: ${theme.colors.black};
  margin-bottom: 10px;
`;

export const Description = styled.p`
  ${fonts.roboto.paragraph};
  color: ${theme.colors.gray};
  margin-bottom: 10px;
`;
