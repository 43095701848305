import styled from "styled-components";

import theme from "../../common/global/theme";
import fonts from "../../utils/fonts";
import {device} from "../../utils/mediaQueries";

export const GalleryContainer = styled.div`
  text-align: center;
  padding: 40px 20px;
  background-color: ${theme.colors.white};
`;

export const MiniTitle = styled.div`
  ${fonts.lato.paragraph};
  color: ${theme.colors.blue};
  margin-bottom: 10px;
`;

export const Title = styled.h2`
  ${fonts.lato.headingH2};
  color: ${theme.colors.black};
  margin-bottom: 30px;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 1200px;
  margin: auto;

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Card = styled.div`
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease-in-out;

  ${Card}:hover & {
    transform: scale(1.05);
  }
`;

export const TitleOverlay = styled.div`
  position: absolute;
  bottom: 20px;
  left: 10px;
  color: ${theme.colors.white};
  padding: 10px 10px;
  border-radius: 5px;
  ${fonts.roboto.headingH5};
  transition: opacity 0.3s ease-in-out;

  ${Card}:hover & {
    opacity: 0;
  }
`;

export const HoverOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 113, 188, 0.7);
  color: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 20px 20px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  ${Card}:hover & {
    opacity: 1;
  }
`;

export const Text = styled.h3`
  ${fonts.roboto.headingH5};
  margin-bottom: 5px;
  padding: 0px 40px 0px 0px;
`;

export const Description = styled.p`
  ${fonts.roboto.paragraph};
  opacity: 0.8;
  margin-bottom: 10px;
  text-align: left;
  padding: 10px 40px 10px 0px;
`;

export const KnowMore = styled.a`
  ${fonts.roboto.paragraph};
  color: ${theme.colors.white}CC;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;
