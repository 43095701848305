const theme = {
  colors: {
    green: "#41BA8D",
    blue: "#00ADEF",
    lightBlue: "#2776BB",
    white: "#FFFFFF",
    gray: "#444",
    darkGray: "#3333",
    lightGray: "#F1F1F1",
    softGray: "#D0CECE",
    secondaryBlack: "#1c1c1c",
    black: "#000000",
    darkBlack: "#292929",
  },
};
export default theme;
