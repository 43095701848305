import {createContext, useState} from "react";

const GlobalContext = createContext();

const GlobalProvider = ({children}) => {
  const [breadcrumbs, setBreadcrumbs] = useState(["Home"]);

  return (
    <GlobalContext.Provider value={{breadcrumbs, setBreadcrumbs}}>
      {children}
    </GlobalContext.Provider>
  );
};

export {GlobalContext, GlobalProvider};
