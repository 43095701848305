import styled from "styled-components";

import theme from "../../../common/global/theme";
import fonts from "../../../utils/fonts";
import {device} from "../../../utils/mediaQueries";

export const Container = styled.div`
  max-width: 1300px;
  margin: 40px auto;
  padding: 20px;
  background-color: ${theme.colors.white};
  color: ${theme.colors.black};
`;

export const ContainerTitles = styled.div`
  text-align: center;
`;

export const MainHeading = styled.h1`
  ${fonts.lato.headingH1};
  color: ${theme.colors.black};
  margin-bottom: 24px;

  @media ${device.mobile} {
    font-size: 32px;
  }
`;

export const MiniTitle = styled.div`
  ${fonts.lato.paragraph};
  color: ${theme.colors.blue};
  margin-bottom: 10px;
`;

export const Title = styled.h2`
  ${fonts.lato.headingH2};
  color: ${theme.colors.black};
  margin-bottom: 30px;
`;

export const Section = styled.section`
  margin-bottom: 40px;
`;

export const SectionTitle = styled.h2`
  ${fonts.lato.headingH2};
  margin-bottom: 16px;

  @media ${device.mobile} {
    font-size: 28px;
  }
`;

export const Paragraph = styled.p`
  ${fonts.lato.paragraph};
  margin-bottom: 16px;
  color: ${theme.colors.gray};

  @media ${device.mobile} {
    font-size: 14px;
  }
`;

export const TwoColumnSection = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
  margin-bottom: 40px;

  @media ${device.tablet} {
    flex-direction: column;
  }

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  flex: 1;
`;

export const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;

  img {
    width: 100%;
    max-width: 500px;
    height: auto;
    display: block;
  }

  @media ${device.tablet} {
    justify-content: center;
    img {
      max-width: 400px;
    }
  }

  @media ${device.mobile} {
    justify-content: center;
    img {
      max-width: 300px;
    }
  }
`;

export const List = styled.ul`
  list-style-type: none;
  padding-left: 0;
`;

export const ListItem = styled.li`
  ${fonts.lato.paragraph};
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  &::before {
    content: "▪";
    color: ${theme.colors.blue};
    font-size: 18px;
    margin-right: 8px;
  }
`;
