import styled from "styled-components";

import theme from "../../common/global/theme";
import fonts from "../../utils/fonts";
import {device} from "../../utils/mediaQueries";

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto 40px;

  &:hover {
    transform: scale(1.02);
  }

  @media ${device.tablet} {
    flex-direction: column;
    width: 100%;
    height: auto;
  }

  @media ${device.mobile} {
    flex-direction: column;
    width: 100%;
    height: auto;
  }
`;

export const Image = styled.img`
  width: 313.81px;
  height: 233px;
  border-radius: 2.5px;
  border: 0.5px solid ${theme.colors.lightGray};
  object-fit: cover;

  @media ${device.tablet} {
    width: 100%;
    height: auto;
  }

  @media ${device.mobile} {
    width: 100%;
    height: auto;
  }
`;

export const Content = styled.div`
  flex: 1;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Header = styled.div`
  text-align: left;
  margin-bottom: 12px;
`;

export const Title = styled.h3`
  ${fonts.lato.headingH4};
  color: ${theme.colors.black};
  margin-bottom: 4px;
`;

export const SubInfo = styled.p`
  ${fonts.lato.paragraph};
  color: ${theme.colors.gray};
  margin-bottom: 10px;
`;

export const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-bottom: 10px;
  align-items: flex-start;
  gap: 20px;

  @media ${device.tablet} {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 12px;
  }

  @media ${device.mobile} {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 10px;
  }
`;

export const InfoItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

export const Icon = styled.img`
  width: 16px;
  height: 16px;
  margin-top: 3px;
`;

export const InfoText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.4;
  text-align: left;
`;

export const Label = styled.span`
  ${fonts.lato.paragraph};
  font-weight: 700;
  color: ${theme.colors.black};
  line-height: 1.2;
`;

export const Value = styled.span`
  ${fonts.lato.paragraph};
  color: ${theme.colors.gray};
  white-space: nowrap;
  line-height: 1.2;
`;

export const DetailsLink = styled.a`
  ${fonts.lato.paragraph};
  color: ${theme.colors.blue};
  font-weight: bold;
  text-decoration: none;
  margin-top: 10px;
  align-self: flex-start;

  &:hover {
    text-decoration: underline;
  }
`;
