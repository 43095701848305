import solutionImage from "../../../assets/images/solutions/ImageSolutions.svg";
import CardTechnology from "../../../components/cardTechnology";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import Questions from "../../../components/questions";
import Carousel from "../../../components/carousel";
import GetInTouch from "../../../components/getInTouch";
import * as S from "./styles";

function Acid() {
  const solutionsData = [
    {
      id: "ActivatedCarbon",
      title: "Activated Carbon",
      description: "Removes impurities from gases.",
    },
    {
      id: "MistEliminators",
      title: "Mist Eliminators",
      description: "Captures mist and fine droplets.",
    },
    {
      id: "GasScrubbers",
      title: "Gas Scrubbers",
      description: "Reduces pollutants in industrial exhaust.",
    },
    {
      id: "Dewpoint",
      title: "Dewpoint",
      description: "Measures moisture levels accurately.",
    },
  ];

  const questionsData = [
    {
      question: "What technologies do you use for emission reduction?",
      answer:
        "<span style=\"font-family: 'Lato', sans-serif\">We utilize a range of technologies including advanced scrubbers, MECS® mist eliminators, mesh pads, activated carbon filters.</span>",
    },
    {
      question: "How can emission reduction solutions benefit my business?",
      answer:
        "<span style=\"font-family: 'Lato', sans-serif\">Our emission control systems help you meet environmental regulations, reduce pollutant output, and improve operational efficiency.</span>",
    },
  ];

  return (
    <>
      <Header />
      <S.Container>
        <S.MainHeading>Acid Mist and Aerosols</S.MainHeading>
        <S.Paragraph>
          Stricter environmental regulations continue to push for further reduction in inorganic
          gaseous emissions, especially nitrogen, chlorine, and phosphate compounds. Emission limits
          for acid gases depend heavily on their toxicity and environmental impact. In fertilizer
          production, particularly for ammonium nitrate, companies now face emission limits
          alongside tight restrictions on water pollution. Mist Eliminators offer a sustainable
          solution by enabling the recovery of pure raw materials. Recovered condensate can be
          reused in the process, reducing waste and improving efficiency. For example, in ammonium
          nitrate production, this can save up to 1800 kg of product per day. Reducing sodium salts
          in chlorine gas improves both drying efficiency and product quality. This is particularly
          relevant in processes involving sulphuric acid, where cleaner output means less
          contamination downstream.
        </S.Paragraph>

        <S.Section>
          <S.SectionTitle>Regulations and Safety</S.SectionTitle>
          <S.SubHeading>Environmental and Regulatory Standards</S.SubHeading>
          <S.Paragraph>
            European directives such as the Industrial Emissions Directive (2010/75/EU) and the
            National Emission Reduction Commitments Directive (2016/2284/EU).
          </S.Paragraph>
          <S.Paragraph>
            Typical limits from BREF BAT conclusions include: Ammonium Nitrate: 1–10 mg/Nm³
            Phosphates as salts: &lt; 5 mg/Nm³ Salts in chlorine drying agents: &lt; 4 mg/l
          </S.Paragraph>
          <S.SubHeading>Health and Safety</S.SubHeading>
          <S.Paragraph>
            Upgrading to more efficient filtration and gas scrubbing technologies significantly
            reduces environmental impact. Ecosystems has successfully implemented similar
            technologies to reduce SO₂ emissions while producing valuable byproducts like ammonium
            sulphate. Ammonium Sulphate reduction. Reducing chlorine and HCl emissions also directly
            contributes to workplace and environmental health, helping to prevent toxic releases of
            hazardous compounds.
          </S.Paragraph>
        </S.Section>

        <S.ApplicationsSection>
          <S.ApplicationsList>
            <S.SectionTitle>Applications</S.SectionTitle>
            <S.List>
              <S.ListItem>Ammonium Nitrate emissions to &lt; 5 mg/Nm³</S.ListItem>
              <S.ListItem>Ammonium Sulphate and Phosphate with emissions &lt; 5 mg/Nm³</S.ListItem>
              <S.ListItem>Improves Nitrogen capture in large Urea production sites</S.ListItem>
              <S.ListItem>
                Optimizes Chlorine production using high-efficiency wet filters
              </S.ListItem>
              <S.ListItem>Enables Alkaline salt removal in hydrogen processing</S.ListItem>
            </S.List>
          </S.ApplicationsList>
          <S.ImageContainer>
            <img src={solutionImage} alt="Worker next to a flue gas desulfurization equipment" />
          </S.ImageContainer>
        </S.ApplicationsSection>

        <S.TwoColumnSection>
          <S.Column>
            <S.SectionTitle>Process Design</S.SectionTitle>
            <S.List>
              <S.ListItem>Temperature Ranges: 5–92°C</S.ListItem>
              <S.ListItem>Flow Rates: 500 to 800,000 Nm³/h</S.ListItem>
              <S.ListItem>Operating Pressure: -100 to +200 mbar</S.ListItem>
              <S.ListItem>Pressure Drop: 10–60 mbar</S.ListItem>
              <S.ListItem>Efficiency: up to 99,9% removal efficiency</S.ListItem>
            </S.List>
          </S.Column>
          <S.Column>
            <S.SectionTitle>Materials</S.SectionTitle>
            <S.List>
              <S.ListItem>Stainless steel (316L, 304L, 904L, high-nickel alloys)</S.ListItem>
              <S.ListItem>FRP, HDPE, and PP</S.ListItem>
              <S.ListItem>Linings of PP or PTFE in acidic environments</S.ListItem>
            </S.List>
          </S.Column>
        </S.TwoColumnSection>
      </S.Container>
      <CardTechnology data={solutionsData} />
      <Questions items={questionsData} />
      <Carousel />
      <GetInTouch />
      <Footer />
    </>
  );
}

export default Acid;
