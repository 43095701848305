import styled from "styled-components";

import theme from "../../common/global/theme";
import fonts from "../../utils/fonts";
import {device} from "../../utils/mediaQueries";

export const SectionWrapper = styled.section`
  display: flex;
  width: 100%;
  min-height: 437px;
  background-image: url(${(props) => props.$backgroundDesktop});
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
  background-color: ${theme.colors.blue};
  padding: 60px 5%;
  box-sizing: border-box;

  @media ${device.tablet} {
    background-image: url(${(props) => props.$backgroundTablet});
    align-items: center;
  }

  @media ${device.mobile} {
    background-image: url(${(props) => props.$backgroundMobile});
    flex-direction: column;
    align-items: center;
    padding: 40px 24px;
  }
`;

export const ContentRight = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 700px;
  width: 100%;

  @media ${device.tablet}, ${device.mobile} {
    align-items: center;
    text-align: center;
    margin: 0 auto;
  }
`;

export const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px 60px;
  width: 100%;
  margin-bottom: 40px;

  @media ${device.tablet} {
    gap: 32px 48px;
  }

  @media ${device.mobile} {
    grid-template-columns: 1fr;
    gap: 24px;
    margin-bottom: 32px;
  }
`;

export const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;

  &.with-border {
    border-left: 4px solid ${theme.colors.white};
    padding-left: 40px;
  }

  @media ${device.mobile} {
    align-items: flex-start;
    text-align: left;
  }
`;

export const StatValue = styled.h2`
  ${fonts.roboto.headingH3};
  color: ${theme.colors.white};

  @media ${device.tablet} {
    font-size: 40px;
  }

  @media ${device.mobile} {
    font-size: 32px;
  }
`;

export const StatLabel = styled.p`
  ${fonts.lato.paragraphLight};
  font-size: 18px;
  color: ${theme.colors.white};

  @media ${device.tablet} {
    font-size: 16px;
  }

  @media ${device.mobile} {
    font-size: 14px;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  flex-direction: row;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const JoinMessage = styled.p`
  ${fonts.lato.paragraph};
  color: ${theme.colors.white};
  font-weight: 400;
  margin: 0;
  text-align: left;

  @media ${device.mobile} {
    text-align: center;
    margin-bottom: 8px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
  flex-wrap: nowrap;
`;
