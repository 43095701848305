import React from "react";

import * as S from "./styles";
import Header from "../../components/header";
import Footer from "../../components/footer";
import GetInTouch from "../../components/getInTouch";
import Numbers from "../../components/numbers";
import IconVision from "../../assets/images/aboutUs/vision.png";
import IconMission from "../../assets/images/aboutUs/mission.png";
import IconValues from "../../assets/images/aboutUs/values.png";

function AboutUs() {
  return (
    <>
      <Header />
      <S.Container>
        <S.PageIntro>
          <S.Subtitle>Nice to meet you!</S.Subtitle>
          <S.Title>We are Ecosystems Europe</S.Title>
          <S.Paragraph>
            Ecosystems specializes in industrial gas purification, designing, building, installing
            and commissioning state-of-the-art methods based on proven technology for an
            environmentally friendly environment. For many emission problems, we seek solutions
            together with the customer, which result in a reduction of air pollution, compliance
            with emission standards and the elimination of nuisances. Whether it is acidic vapors
            such as SO2, H2S, HCl or NOx, odor reduction or flue gas cleaning, Econebra / Ecosystems
            has a solution. The emphasis today is on sustainability by reusing and saving energy and
            enabling recycling waste. We have a wide range of modern technologies available and can
            be viewed on the website. From small filtration systems for a local problem to complex
            gas purification plants. All of our technologies have laboratory test units that can be
            run to investigate the feasibility of the solution.
          </S.Paragraph>
        </S.PageIntro>

        <S.CardsContainer>
          <S.Card>
            <S.SideBar>
              <S.TopBar color="#41BA8D" />
              <S.Divider />
            </S.SideBar>
            <S.Content>
              <S.HeaderRow>
                <S.Icon src={IconVision} alt="Vision Icon" />
                <S.CardTitle color="#41BA8D">Our Vision</S.CardTitle>
              </S.HeaderRow>
              <S.CardDescription>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris rhoncus enim a leo
                feugiat, non interdum sem condimentum. Quisque blandit lorem quis felis pellentesque
                luctus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames
                ac turpis egestas.
              </S.CardDescription>
            </S.Content>
          </S.Card>

          <S.Card>
            <S.SideBar>
              <S.TopBar color="#2776BB" />
              <S.Divider />
            </S.SideBar>
            <S.Content>
              <S.HeaderRow>
                <S.Icon src={IconMission} alt="Mission Icon" />
                <S.CardTitle color="#2776BB">Our Mission</S.CardTitle>
              </S.HeaderRow>
              <S.CardDescription>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris rhoncus enim a leo
                feugiat, non interdum sem condimentum. Quisque blandit lorem quis felis pellentesque
                luctus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames
                ac turpis egestas.
              </S.CardDescription>
            </S.Content>
          </S.Card>

          <S.Card>
            <S.SideBar>
              <S.TopBar color="#00ADEF" />
              <S.Divider />
            </S.SideBar>
            <S.Content>
              <S.HeaderRow>
                <S.Icon src={IconValues} alt="Values Icon" />
                <S.CardTitle color="#00ADEF">Our Values</S.CardTitle>
              </S.HeaderRow>
              <S.CardDescription>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris rhoncus enim a leo
                feugiat, non interdum sem condimentum. Quisque blandit lorem quis felis pellentesque
                luctus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames
                ac turpis egestas.
              </S.CardDescription>
            </S.Content>
          </S.Card>
        </S.CardsContainer>
      </S.Container>

      <Numbers />
      <GetInTouch />
      <Footer />
    </>
  );
}

export default AboutUs;
