import React, {useEffect, useContext} from "react";

import Header from "../../../components/header";
import Questions from "../../../components/questions";
import CompanyCarousel from "../../../components/carousel";
import GetInTouch from "../../../components/getInTouch";
/*import Servicess from "../../../components/Servicess";*/
import Footer from "../../../components/footer";
import carbonImage from "../../../assets/images/solutions/ImageSolutions.svg";
import InstallationServicess from "../../../components/Servicess/installationServices";
import Breadcrumbs from "../../../components/breadcrumbs";
import * as S from "./styles";
import {GlobalContext} from "../../../context/global/globalContext";

const questionsData = [
  {
    question: "What technologies do you use for emission reduction?",
    answer:
      "<span style=\"font-family: 'Lato', sans-serif\">We utilize a range of technologies including advanced scrubbers, MECS® mist eliminators, mesh pads, activated carbon filters.</span>",
  },
  {
    question: "How can emission reduction solutions benefit my business?",
    answer:
      "<span style=\"font-family: 'Lato', sans-serif\">Our emission control systems help you meet environmental regulations, reduce pollutant output, and improve operational efficiency.</span>",
  },
];

function EmissionsMeasurements() {
  const {setBreadcrumbs} = useContext(GlobalContext);

  useEffect(() => {
    setBreadcrumbs([
      {label: "Home", path: "/"},
      {label: "Services", path: "/services"},
      {label: "Emissions Measurements", path: "/services/emissions-measurements"},
    ]);
  }, [setBreadcrumbs]);

  return (
    <>
      <Header />
      <S.PageContainer>
        <Breadcrumbs />

        <S.SectionTitle>Emissions Measurements</S.SectionTitle>
        <S.Paragraph>
          At Ecosystems Europe, we understand the importance of reliable measurements and clear
          analyses in complying with environmental laws and regulations. We offer a wide range of
          measurement services that are essential for assessing the efficiency and performance of
          your installation. Our experienced team works quickly, safely, and accurately—delivering
          dependable data you can act on, whether for routine monitoring, troubleshooting, or
          reporting.
        </S.Paragraph>

        <S.TwoColumnSection>
          <S.Column>
            <S.SectionTitle>Applications</S.SectionTitle>
            <S.List>
              <S.ListItem>
                Mist Sampling - Measure mist concentrations to optimize emission control
              </S.ListItem>
              <S.ListItem>
                PeGaSys Measurement – Evaluate catalyst and converter performance
              </S.ListItem>
              <S.ListItem>
                FID / VOC Measurement – Detect hydrocarbons and volatile compounds
              </S.ListItem>
              <S.ListItem>
                Flow & Pressure Measurement – Assess equipment efficiency and compliance
              </S.ListItem>
              <S.ListItem>
                Carbon Analysis – Check remaining capacity of activated carbon in our lab
              </S.ListItem>
              <S.ListItem>
                Flue Gas Measurement – Measure SO₂, SO₃, NOₓ, Cl₂, HCl, NH3 and O₂ levels
              </S.ListItem>
            </S.List>
          </S.Column>
          <S.ImageContainer>
            <img src={carbonImage} alt="Carbon Scrubber Equipment" />
          </S.ImageContainer>
        </S.TwoColumnSection>

        <S.SectionTitle>What we offer</S.SectionTitle>
        <S.Paragraph>
          A complete range of measurement services tailored to acid plants and industrial processes,
          including mist sampling, VOCs, flow, pressure, and flue gas analysis. We also offer carbon
          testing in our in-house lab and provide specific solutions like SO₂ and stick tests for
          sulphuric acid plants.
        </S.Paragraph>

        <S.CenteredSection>
          <InstallationServicess />
        </S.CenteredSection>

        <S.SpacedBlock>
          <Questions items={questionsData} />
        </S.SpacedBlock>

        <S.Word>Our Services</S.Word>
        <S.Title>
          We partner with industry leaders to create clean alternatives to <br />
          traditional industrial processes
        </S.Title>

        <S.FullWidthSection>
          <CompanyCarousel />
        </S.FullWidthSection>
        <S.FullWidthSection>
          <GetInTouch />
        </S.FullWidthSection>
      </S.PageContainer>
      <Footer />
    </>
  );
}

export default EmissionsMeasurements;
