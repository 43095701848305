import solutionImage from "../../../assets/images/solutions/ImageSolutions.svg";
import CardTechnology from "../../../components/cardTechnology";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import Questions from "../../../components/questions";
import Carousel from "../../../components/carousel";
import GetInTouch from "../../../components/getInTouch";
import * as S from "./styles";

function AcidFumes() {
  const solutionsData = [
    {
      id: "ActivatedCarbon",
      title: "Activated Carbon",
      description: "Removes impurities from gases.",
    },
    {
      id: "MistEliminators",
      title: "Mist Eliminators",
      description: "Captures mist and fine droplets.",
    },
    {
      id: "GasScrubbers",
      title: "Gas Scrubbers",
      description: "Reduces pollutants in industrial exhaust.",
    },
    {
      id: "Dewpoint",
      title: "Dewpoint",
      description: "Measures moisture levels accurately.",
    },
  ];
  const questionsData = [
    {
      question: "What technologies do you use for emission reduction?",
      answer:
        "<span style=\"font-family: 'Lato', sans-serif\">We utilize a range of technologies including advanced scrubbers, MECS® mist eliminators, mesh pads, activated carbon filters.</span>",
    },
    {
      question: "How can emission reduction solutions benefit my business?",
      answer:
        "<span style=\"font-family: 'Lato', sans-serif\">Our emission control systems help you meet environmental regulations, reduce pollutant output, and improve operational efficiency.</span>",
    },
  ];
  return (
    <>
      <Header />
      <S.Container>
        <S.MainHeading>Acid Fumes & Inorganics</S.MainHeading>
        <S.Paragraph>
          Growing environmental pressure continues to drive the need for reduction of inorganic
          gaseous emissions, with a strong focus on nitrogen compounds, acid gases, and H₂S.
          Emission limits are based on both toxicity and environmental impact. Instead of chemical
          neutralization with caustic soda or sulphuric acid, water-based scrubbing is preferred for
          its sustainability and ability to support chemical recovery. Ecosystems has developed
          several NH₃ and HCl scrubber systems that meet the world’s most stringent regulations —
          for example, 1-2 mg/Nm³ on HCl at pickling lines.
        </S.Paragraph>

        <S.Section>
          <S.SectionTitle>Regulations and Safety</S.SectionTitle>
          <S.SubHeading>Environmental and Regulatory Standards</S.SubHeading>
          <S.Paragraph>
            Strict emission limits are defined at both EU and national levels to reduce the impact
            of industrial gases on health and the environment. Industrial Emissions Directive (IED)
            — Directive 2010/75/EU NEC Directive — 2016/2284/EU BAT-AEL: Best Available Technology —
            Associated Emission LevelsEmission Benchmarks (Daily Average): HCl (Pickling lines): 0.5
            – 5 mg/Nm³ @ 11% O₂ NH₃: 1–10 mg/Nm³ (Fertilizers), 5–30 mg/Nm³ (Waste treatment) H₂S:
            0.1 – 5 mg/Nm³ (most sectors), 5 – 20 mg/Nm³ (pulp & paper)
          </S.Paragraph>
          <S.SubHeading>Health and Safety</S.SubHeading>
          <S.Paragraph>
            Efficient removal of acid and nitrogen-based emissions is critical not only for
            environmental protection but also for human health and equipment longevity. H₂S
            emissions from coke, biogas, and waste plants are toxic and odorous; reliable scrubbing
            is essential HCl is corrosive and harmful when inhaled, causing material degradation and
            health risks NH₃ emissions contribute to soil nitrogen saturation and threaten
            biodiversity.
          </S.Paragraph>
        </S.Section>

        <S.ApplicationsSection>
          <S.ApplicationsList>
            <S.SectionTitle>Applications</S.SectionTitle>
            <S.List>
              <S.ListItem>
                Reduce HCl emissions with over 99% efficiency, using water instead of NaOH
              </S.ListItem>
              <S.ListItem>
                Loading/unloading operations, offering low-maintenance, operator-free design
              </S.ListItem>
              <S.ListItem>
                NH₃ scrubbers with structured packing reduce ammonia to 1-2 mg/Nm³ in one or two
                stages
              </S.ListItem>
              <S.ListItem>
                H₂S treatment systems combined with high-efficiency mist eliminators to capture SO₃
                and amine mis
              </S.ListItem>
            </S.List>
          </S.ApplicationsList>
          <S.ImageContainer>
            <img src={solutionImage} alt="Worker next to a flue gas desulfurization equipment" />
          </S.ImageContainer>
        </S.ApplicationsSection>

        <S.TwoColumnSection>
          <S.Column>
            <S.SectionTitle>Process Design</S.SectionTitle>
            <S.List>
              <S.ListItem>Temperature Range: 5 to 90°C </S.ListItem>
              <S.ListItem>Flow Rates: Designed for 500 to 150,000 Nm³/h,</S.ListItem>
              <S.ListItem>Operating Pressure: -10 to +150 mbar,</S.ListItem>
              <S.ListItem>Pressure Drop: 10–25 mbar</S.ListItem>
              <S.ListItem>Efficiency: up to 99,9% removal efficiency</S.ListItem>
            </S.List>
          </S.Column>
          <S.Column>
            <S.SectionTitle>Materials</S.SectionTitle>
            <S.List>
              <S.ListItem>Carbon steel</S.ListItem>
              <S.ListItem>Stainless steel (316L, 304L, 904L, high-nickel alloys)</S.ListItem>
              <S.ListItem>FRP, HDPE, and PP</S.ListItem>
              <S.ListItem>linings of PP or PTFE in acidic enviroments</S.ListItem>
            </S.List>
          </S.Column>
        </S.TwoColumnSection>
      </S.Container>
      <CardTechnology data={solutionsData} />
      <Questions items={questionsData} />
      <Carousel />
      <GetInTouch />
      <Footer />
    </>
  );
}

export default AcidFumes;
