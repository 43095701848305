import {UpOutlined as Arrow} from "@ant-design/icons";
import styled from "styled-components";

export const Button = styled.button`
  position: fixed;
  bottom: 115px;
  right: 24px;
  z-index: 1000;
  background-color: #00aaff;
  color: white;
  font-size: 18px;
  border: none;
  border-radius: 8px;
  padding: 10px 14px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s ease-in-out;

  &:hover {
    background-color: #0094dd;
  }

  @media (max-width: 768px) {
    bottom: 16px;
    right: 16px;
    padding: 8px 12px;
  }
`;

export const ArrowIcon = styled(Arrow)``;
