import styled from "styled-components";

import theme from "../../common/global/theme";
import {device} from "../../utils/mediaQueries";
import fonts from "../../utils/fonts";

export const Container = styled.div`
  display: flex;
  width: 1040px;
  height: 242px;
  border-radius: 12px;
  background: #2776bb;
  overflow: hidden;
  margin: 64px auto;
  position: relative;

  @media ${device.tablet} {
    width: calc(100% - 48px);
    height: 242px;
    flex-direction: row;
    border-radius: 12px;
    margin: 48px auto;
  }

  @media ${device.mobile} {
    width: calc(100% - 32px);
    height: auto;
    flex-direction: column;
    border-radius: 12px;
    margin: 32px auto;
    background: #2776bb;
    padding: 0;
    box-sizing: border-box;
  }
`;

export const ImageContainer = styled.div`
  width: 50%;
  height: auto;

  @media ${device.tablet} {
    width: 50%;
  }

  @media ${device.mobile} {
    width: 100%;
    background: #2776bb;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

export const TextContainer = styled.div`
  width: 50%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  color: ${theme.colors.white};

  @media ${device.tablet} {
    width: 50%;
    padding: 24px 32px;
  }

  @media ${device.mobile} {
    width: 100%;
    padding: 24px 16px;
  }
`;

export const Label = styled.a`
  color: #00adef;
  font-family: ${fonts.lato.headingH3.fontFamily};
  font-weight: ${fonts.lato.headingH3.fontWeight};
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.4px;
  text-align: left;
  width: fit-content;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const Heading = styled.h2`
  font-size: 16px;
  font-weight: ${fonts.lato.headingH3.fontWeight};
  line-height: 24px;
  font-family: ${fonts.lato.headingH3.fontFamily};
  margin: 0;
  color: ${theme.colors.white};

  @media ${device.tablet} {
    font-size: 15px;
    line-height: 22px;
  }

  @media ${device.mobile} {
    font-size: 14px;
    line-height: 22px;
  }
`;

export const Button = styled.a`
  width: fit-content;
  background: ${theme.colors.green};
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    background: #45a049;
  }

  &:active {
    transform: translateY(1px);
  }
`;
