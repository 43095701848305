import React, {useState} from "react";

import Button from "../button";
import * as S from "./styles";

function Questions({items, buttonLabel = "Get in touch", onButtonClick}) {
  const [openIndex, setOpenIndex] = useState(null);

  function toggleIndex(index) {
    setOpenIndex(openIndex === index ? null : index);
  }

  return (
    <S.Container>
      <S.Title>Do you have questions?</S.Title>
      {items.map((item, index) => (
        <S.QuestionBlock key={index}>
          <S.QuestionHeader onClick={() => toggleIndex(index)}>
            <S.QuestionText>{item.question}</S.QuestionText>
            <S.ToggleIcon>
              {openIndex === index ? <S.IconArrow /> : <S.IconDownArrow />}
            </S.ToggleIcon>
          </S.QuestionHeader>
          {openIndex === index && <S.Answer dangerouslySetInnerHTML={{__html: item.answer}} />}
        </S.QuestionBlock>
      ))}
      <S.Footer>
        <S.FooterText>My question is not here.</S.FooterText>
        <Button text="Get in touch" onClick={onButtonClick}>
          {buttonLabel}
        </Button>
      </S.Footer>
    </S.Container>
  );
}

export default Questions;
