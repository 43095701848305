import Footer from "../../components/footer";
import Header from "../../components/header";
import * as S from "./styles";

function Privacy() {
  return (
    <>
      <Header />
      <S.Container>
        <S.Title>Privacy policy for Ecosystems Europe BV</S.Title>
        <S.Paragraph>
          At Ecosystems Europe BV, we are committed to protecting the privacy and security of our
          customers and website visitors in accordance with the European Union&apos;s General Data
          Protection Regulation (EU GDPR) and other applicable data protection laws. This privacy
          policy explains how we collect, use, and safeguard your personal information when you
          visit our website, interact with us, or purchase our products and services.
        </S.Paragraph>

        <S.Section>
          <S.SubTitle>Information we collect</S.SubTitle>
          <S.Paragraph>
            We collect personal information when you visit our website, register for our newsletter,
            or send an enquiry document. This may include your name, email address, mailing address,
            and phone number. We collect and process your personal data only for specific, explicit,
            and legitimate purposes and in accordance with the EU GDPR and other applicable data
            protection laws.
          </S.Paragraph>
        </S.Section>

        <S.Section>
          <S.SubTitle>How we use your information</S.SubTitle>
          <S.Paragraph>
            We use the information we collect to provide you with our products and services, process
            your orders, communicate with you, and send you promotional materials. We may also use
            your information for research purposes and to improve our website and services. We will
            always process your personal data with the highest level of diligence in order to
            fulfill the EU GDPR.
          </S.Paragraph>
        </S.Section>

        <S.Section>
          <S.SubTitle>Sharing your information</S.SubTitle>
          <S.Paragraph>
            We do not sell, trade, or rent your personal information to third parties. However, we
            may share your information with trusted third-party service providers who assist us in
            operating our website and providing our services. We may also share your information
            when required by law or to protect our rights, property, or safety. We ensure that any
            third-party service providers we use are GDPR-compliant and process your personal data
            only in accordance with our instructions.
          </S.Paragraph>
        </S.Section>

        <S.Section>
          <S.SubTitle>Security of your information</S.SubTitle>
          <S.Paragraph>
            We take appropriate technical and organizational measures to protect your personal
            information from unauthorized access, use, or disclosure. We use industry-standard
            encryption technology and secure servers to protect your data and comply with data
            protection laws.
          </S.Paragraph>
        </S.Section>

        <S.Section>
          <S.SubTitle>Your rights</S.SubTitle>
          <S.Paragraph>
            You have the right to access, correct, or delete your personal information, and to
            object to or restrict its processing. You also have the right to data portability and to
            withdraw your consent at any time. You can exercise these rights by contacting us using
            the contact details provided on our website.
          </S.Paragraph>
        </S.Section>

        <S.Section>
          <S.SubTitle>Changes to this policy</S.SubTitle>
          <S.Paragraph>
            We may update this privacy policy from time to time to reflect changes in our practices
            and applicable laws. We encourage you to review this policy periodically.
          </S.Paragraph>
        </S.Section>

        <S.Section>
          <S.SubTitle>Contact us</S.SubTitle>
          <S.Paragraph>
            If you have any questions or concerns about our privacy policy or the handling of your
            personal information, please contact us at the following address:
          </S.Paragraph>
          <S.ContactInfo>
            <p>Ecosystems Europe BV</p>
            <p>De Droogmakerij 20D</p>
            <p>1851 LX HEILOO</p>
            <p>The Netherlands</p>
            <p>+31 72 8200 270</p>
            <p>info@ecosystemseurope.com</p>
          </S.ContactInfo>
        </S.Section>
      </S.Container>
      <Footer />
    </>
  );
}

export default Privacy;
