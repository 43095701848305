import CardTechnology from "../../components/cardTechnology";
import Carousel from "../../components/carousel";
import Footer from "../../components/footer";
import ContactForm from "../../components/forms";
import Gallery from "../../components/gallery";
import GoogleMaps from "../../components/googleMaps";
import Header from "../../components/header";
import Hero from "../../components/hero";
import Numbers from "../../components/numbers";
import ScrollToTopButton from "../../components/scrollToTop";
import Servicess from "../../components/Servicess";
import * as S from "./styles";

const techData = [
  {id: "ActivatedCarbon", title: "Activated Carbon", description: "Removes impurities from gases."},
  {
    id: "MistEliminators",
    title: "Mist Eliminators",
    description: "Captures mist and fine droplets.",
  },
  {
    id: "GasScrubbers",
    title: "Gas Scrubbers",
    description: "Reduces pollutants in industrial exhaust.",
  },
  {id: "Dewpoint", title: "Dewpoint", description: "Measures moisture levels accurately."},
];

function Home() {
  return (
    <S.PageWrapper>
      <Header />
      <Hero />
      <S.CompaniesSection>
        <S.CompaniesText>Trusted by teams from leading companies</S.CompaniesText>
      </S.CompaniesSection>
      <Carousel />
      <Gallery />
      <S.TechnologiesSubtitle>Our Technologies</S.TechnologiesSubtitle>
      <S.TechnologiesTitle>Start improving your environmental impact.</S.TechnologiesTitle>
      <S.TechnologiesIntroText>
        We collaborate with industry leaders such as MECS Global and Chemviron Carbons Ltd,
        Socrematic France, to create clean alternatives to traditional industrial processes and make
        the world a better place for all.
      </S.TechnologiesIntroText>
      <CardTechnology data={techData} />
      <Servicess />
      <Numbers />

      <S.ContactSection>
        <S.LeftContent>
          <ContactForm />

          <S.ContactInfo>
            <S.InfoBlock>
              <S.IconPhone />
              <span>Phone</span>
              <span>+31(0)72 8200 270</span>
            </S.InfoBlock>
            <S.InfoBlock>
              <S.IconEmail />
              <span>E-mail</span>
              <span>info@ecosystemseurope.com</span>
            </S.InfoBlock>
          </S.ContactInfo>
        </S.LeftContent>

        <S.RightContent>
          <S.TextBlock>
            <span className="highlight">Get in Touch</span>
            <h2>Let us help you achieve optimal performance and cleaner environment for all.</h2>
            <p>
              Don’t hesitate to contact us today to learn more about our services, products, and to
              request an expert visit.
            </p>
          </S.TextBlock>

          <S.MapWrapperWithBlock>
            <GoogleMaps />
          </S.MapWrapperWithBlock>
        </S.RightContent>
      </S.ContactSection>

      <ScrollToTopButton />
      <Footer />
    </S.PageWrapper>
  );
}

export default Home;
