import styled from "styled-components";

import {ReactComponent as SetaMenu} from "../../assets/images/DownArrow.svg";
import {ReactComponent as LogoLanguage} from "../../assets/images/IconLanguage.svg";
import {ReactComponent as IconMenu} from "../../assets/images/IconMenu.svg";
import {ReactComponent as LogoEcosystems} from "../../assets/images/LogoEco.svg";
import fonts from "../../utils/fonts";
import {device} from "../../utils/mediaQueries";

export const HeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
  position: ${({isSticky}) => (isSticky ? "fixed" : "aboslute")};
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.3s ease-in-out;
  z-index: 100;
  background: ${({isSticky}) => (isSticky ? "white" : "transparent")};
  box-shadow: ${({isSticky}) => (isSticky ? "0 4px 6px rgba(0, 0, 0, 0.1)" : "none")};
`;

export const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  transition: padding 0.3s ease-in-out;
`;

export const Logo = styled(LogoEcosystems)`
  width: 160px;
  height: auto;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  filter: ${({isSticky}) => (isSticky ? "brightness(0.8)" : "none")};

  &:hover {
    transform: scale(1.05);
  }
`;

export const Language = styled(LogoLanguage)`
  cursor: pointer;
  margin-right: 20px;
  fill: black;

  @media ${device.mobile} {
    margin-right: 2px;
  }
`;

export const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  opacity: 1;
  visibility: visible;

  a {
    color: ${({isSticky, theme}) => (isSticky ? theme.colors.darkGray : theme.colors.black)};
    transition: color 0.3s ease-in-out;
    opacity: 1;
    visibility: visible;
  }
`;

export const Seta = styled(SetaMenu)`
  margin-left: 8px;
  cursor: pointer;
`;

export const MenuIcon = styled(IconMenu)`
  cursor: pointer;
  size: 24px;
  display: none;

  @media ${device.tablet} {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 10px 0px 10px;
  }

  @media ${device.mobile} {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px -23px 0px 10px;
  }
`;

export const CollapsibleMenu = styled.nav`
  display: flex;
  padding: 0;
  position: static;
  width: auto;
  gap: 32px;
  border-radius: 0;
  ${fonts.lato.paragraph}
  opacity: 1;
  visibility: visible;

  @media ${device.tablet}, ${device.mobile} {
    display: ${({isOpen}) => (isOpen ? "flex" : "none")};
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: ${({theme}) => theme.colors.white};
    padding: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

export const MenuItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  color: ${({theme}) => theme.colors.darkBlack};
  text-decoration: none;
  margin: 8px 0;
  padding: 8px 16px;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  opacity: 1;
  visibility: visible;

  &:hover > div {
    display: block;
  }
`;

export const DropdownMenu = styled.div`
  display: none;
  position: absolute;
  background-color: ${({theme}) => theme.colors.softGray};
  min-width: 250px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 100%;
  left: 0;
  border-radius: 8px;
  overflow: hidden;
`;

export const Ancora = styled.a`
  color: ${({theme}) => theme.colors.darkBlack};
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({theme}) => theme.colors.lightGray};
  }
`;

export const AlignItems = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const HideOnMobile = styled.div`
  @media ${device.mobile} {
    display: none;
  }
`;
