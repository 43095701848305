import React, {useEffect, useContext} from "react";

import Header from "../../../components/header";
import Questions from "../../../components/questions";
import CompanyCarousel from "../../../components/carousel";
import GetInTouch from "../../../components/getInTouch";
/*import Servicess from "../../../components/Servicess";*/
import Footer from "../../../components/footer";
import carbonImage from "../../../assets/images/solutions/ImageSolutions.svg";
import InstallationServicess from "../../../components/Servicess/installationServices";
import Breadcrumbs from "../../../components/breadcrumbs";
import * as S from "./styles";
import {GlobalContext} from "../../../context/global/globalContext";

const questionsData = [
  {
    question: "What technologies do you use for emission reduction?",
    answer:
      "<span style=\"font-family: 'Lato', sans-serif\">We utilize a range of technologies including advanced scrubbers, MECS® mist eliminators, mesh pads, activated carbon filters.</span>",
  },
  {
    question: "How can emission reduction solutions benefit my business?",
    answer:
      "<span style=\"font-family: 'Lato', sans-serif\">Our emission control systems help you meet environmental regulations, reduce pollutant output, and improve operational efficiency.</span>",
  },
];

function NonDestructiveTesting() {
  const {setBreadcrumbs} = useContext(GlobalContext);

  useEffect(() => {
    setBreadcrumbs([
      {label: "Home", path: "/"},
      {label: "Services", path: "/services"},
      {label: "Non-DestructiveTesting", path: "/services/non-destructive-testing"},
    ]);
  }, [setBreadcrumbs]);

  return (
    <>
      <Header />
      <S.PageContainer>
        <Breadcrumbs />

        <S.SectionTitle>Non-Destructive Testing</S.SectionTitle>
        <S.Paragraph>
          Make your sulphuric acid plant more reliable with targeted, advanced NDT solutions. Our
          team understands the function and criticality of each piece of equipment, allowing for
          precise and efficient inspections. With the right testing methods and process knowledge,
          we help detect corrosion and damage early—minimizing risks, reducing downtime, and
          extending equipment life.
        </S.Paragraph>

        <S.TwoColumnSection>
          <S.Column>
            <S.SectionTitle>Applications</S.SectionTitle>
            <S.List>
              <S.ListItem>Acid coolers </S.ListItem>
              <S.ListItem>Gas/gas heat exchangers </S.ListItem>
              <S.ListItem>SO₃ coolers & waste heat boilers</S.ListItem>
              <S.ListItem>Preheaters & fin-fan exchangers</S.ListItem>
              <S.ListItem>Oil coolers, piping & tanks</S.ListItem>
            </S.List>
          </S.Column>
          <S.ImageContainer>
            <img src={carbonImage} alt="Carbon Scrubber Equipment" />
          </S.ImageContainer>
        </S.TwoColumnSection>

        <S.SectionTitle>What we offer</S.SectionTitle>
        <S.Paragraph>
          We provide a full range of advanced inspection techniques, including Eddy Current (ET) and
          Eddy Current Array (ECA) for detecting surface and near-surface flaws. For deeper
          inspection of ferromagnetic tubing, we offer Remote Field Testing (RFT) and Near Field
          Testing (NFT). Additionally, we use Airborne Ultrasound (UT) to detect leaks and
          structural weaknesses in a non-intrusive, reliable manner
        </S.Paragraph>

        <S.CenteredSection>
          <InstallationServicess />
        </S.CenteredSection>

        <S.SpacedBlock>
          <Questions items={questionsData} />
        </S.SpacedBlock>

        <S.Word>Our Services</S.Word>
        <S.Title>
          We partner with industry leaders to create clean alternatives to <br />
          traditional industrial processes
        </S.Title>

        <S.FullWidthSection>
          <CompanyCarousel />
        </S.FullWidthSection>
        <S.FullWidthSection>
          <GetInTouch />
        </S.FullWidthSection>
      </S.PageContainer>
      <Footer />
    </>
  );
}

export default NonDestructiveTesting;
