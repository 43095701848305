import solutionImage from "../../../assets/images/solutions/ImageSolutions.svg";
import CardTechnology from "../../../components/cardTechnology";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import * as S from "./styles";

function Fertilizer() {
  const solutionsData = [
    {
      id: "ActivatedCarbon",
      title: "Activated Carbon",
      description: "Removes impurities from gases.",
    },
    {
      id: "MistEliminators",
      title: "Mist Eliminators",
      description: "Captures mist and fine droplets.",
    },
    {
      id: "GasScrubbers",
      title: "Gas Scrubbers",
      description: "Reduces pollutants in industrial exhaust.",
    },
    {
      id: "Dewpoint",
      title: "Dewpoint",
      description: "Measures moisture levels accurately.",
    },
  ];
  return (
    <>
      <Header />
      <S.Container>
        <S.MainHeading>Flue Gas Desulfurization</S.MainHeading>
        <S.Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris rhoncus enim a leo
          feugiat, non interdum sem condimentum. Quisque blandit lorem quis felis pellentesque
          luctus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac
          turpis egestas. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere
          cubilia curae; Proin lacinia nunc nisl, blandit fermentum quam rutrum sit amet. Fusce ut
          porta libero. Maecenas scelerisque eros id sem gravida faucibus. Orci varius natoque
          penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse at nisl
          dignissim, dictum erat semper, feugiat nulla. Mauris semper leo at nulla cursus venenatis.
          Pellentesque fermentum, lectus sed egestas consequat, lectus dui iaculis orci, ac
          imperdiet ligula mauris et turpis. In congue iaculis pretium. In ac diam aliquet,
          vestibulum mi non, finibus orci.
        </S.Paragraph>

        <S.Section>
          <S.SectionTitle>Regulations and Safety</S.SectionTitle>
          <S.SubHeading>Environmental and Regulatory Standards</S.SubHeading>
          <S.Paragraph>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris rhoncus enim a leo
            feugiat, non interdum sem condimentum. Quisque blandit lorem quis felis pellentesque
            luctus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac
            turpis egestas. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere
            cubilia curae; Proin lacinia nunc nisl, blandit fermentum quam rutrum sit amet. Fusce ut
            porta libero. Maecenas scelerisque eros id sem gravida faucibus. Orci varius natoque
            penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse at nisl
            dignissim, dictum erat semper, feugiat nulla. Mauris semper leo at nulla cursus
            venenatis. Pellentesque fermentum, lectus sed egestas consequat, lectus dui iaculis
            orci, ac imperdiet ligula mauris et turpis. In congue iaculis pretium. In ac diam
            aliquet, vestibulum mi non, finibus orci.
          </S.Paragraph>
          <S.SubHeading>Health and Safety</S.SubHeading>
          <S.Paragraph>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris rhoncus enim a leo
            feugiat, non interdum sem condimentum. Quisque blandit lorem quis felis pellentesque
            luctus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac
            turpis egestas. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere
            cubilia curae; Proin lacinia nunc nisl, blandit fermentum quam rutrum sit amet. Fusce ut
            porta libero. Maecenas scelerisque eros id sem gravida faucibus. Orci varius natoque
            penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse at nisl
            dignissim, dictum erat semper, feugiat nulla. Mauris semper leo at nulla cursus
            venenatis. Pellentesque fermentum, lectus sed egestas consequat, lectus dui iaculis
            orci, ac imperdiet ligula mauris et turpis. In congue iaculis pretium. In ac diam
            aliquet, vestibulum mi non, finibus orci.
          </S.Paragraph>
        </S.Section>

        <S.ApplicationsSection>
          <S.ApplicationsList>
            <S.SectionTitle>Applications</S.SectionTitle>
            <S.List>
              <S.ListItem>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</S.ListItem>
              <S.ListItem>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</S.ListItem>
              <S.ListItem>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</S.ListItem>
              <S.ListItem>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</S.ListItem>
            </S.List>
          </S.ApplicationsList>
          <S.ImageContainer>
            <img src={solutionImage} alt="Worker next to a flue gas desulfurization equipment" />
          </S.ImageContainer>
        </S.ApplicationsSection>

        <S.TwoColumnSection>
          <S.Column>
            <S.SectionTitle>Process Design</S.SectionTitle>
            <S.List>
              <S.ListItem>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</S.ListItem>
              <S.ListItem>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</S.ListItem>
              <S.ListItem>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</S.ListItem>
              <S.ListItem>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</S.ListItem>
            </S.List>
          </S.Column>
          <S.Column>
            <S.SectionTitle>Materials</S.SectionTitle>
            <S.List>
              <S.ListItem>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</S.ListItem>
              <S.ListItem>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</S.ListItem>
              <S.ListItem>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</S.ListItem>
              <S.ListItem>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</S.ListItem>
            </S.List>
          </S.Column>
        </S.TwoColumnSection>
      </S.Container>
      <CardTechnology data={solutionsData} />
      <Footer />
    </>
  );
}

export default Fertilizer;
