import React from "react";

import carbonImage from "../../../assets/images/solutions/ImageSolutions.svg";
import CardTechnology from "../../../components/cardTechnology";
import CompanyCarousel from "../../../components/carousel";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import * as S from "./styles";
import FilterCard from "../../../components/filterCard";
import GetInTouch from "../../../components/getInTouch";
import Questions from "../../../components/questions";

function Gas() {
  const gasData = [
    {
      id: "Filter",
      title: "Activated Wet Scrubbers",
      description:
        "These systems use liquid (usually water or chemical solutions) to capture gas pollutants. The gas stream is passed through a scrubbing liquid, which removes particulates and/or gases by absorption. They are ideal for removing acidic gases such as SO2, HCl, and ammonia.",
    },
    {
      id: "Filter",
      title: "Dry Scrubbers",
      description:
        "These systems utilize dry reagents or slurry sprays to neutralize pollutants. Commonly used in flue gas desulfurization processes, dry scrubbers help remove acidic gases and particulates from industrial exhausts without water usage.",
    },
    {
      id: "Filter",
      title: "Electrostatic Precipitators",
      description:
        "These devices use electric charges to remove particles from gas streams. They are especially effective in controlling fine particulate matter from industrial processes such as power generation, cement production, and chemical manufacturing.",
    },
  ];

  const questionsData = [
    {
      question: "What technologies do you use for emission reduction?",
      answer:
        "<span style=\"font-family: 'Lato', sans-serif\">We utilize a range of technologies including advanced scrubbers, MECS® mist eliminators, mesh pads, activated carbon filters.</span>",
    },
    {
      question: "How can emission reduction solutions benefit my business?",
      answer:
        "<span style=\"font-family: 'Lato', sans-serif\">Our emission control systems help you meet environmental regulations, reduce pollutant output, and improve operational efficiency.</span>",
    },
  ];

  return (
    <>
      <Header />
      <S.Container>
        <S.MainHeading>Gas Scrubbers</S.MainHeading>
        <S.Paragraph>
          Gas scrubbers are systems designed to remove harmful substances from industrial exhaust
          gases. These systems work by bringing polluted air or gas into contact with a washing
          liquid that absorbs contaminants such as acid gases, amines, and organic
          pollutants—effectively solving emission and odour problems.
        </S.Paragraph>
        <S.Paragraph>
          With over 35 years of experience and references worldwide, our gas scrubbers are built for
          efficiency, low maintenance, and resistance to corrosion and heat. Thanks to their open,
          clog-free designs, our gas scrubbers ensure long-lasting performance and minimal downtime,
          even in harsh industrial environments.
        </S.Paragraph>

        <S.TwoColumnSection>
          <S.Column>
            <S.SectionTitle>How Gas Scrubbers Work</S.SectionTitle>
            <S.Paragraph>
              Gas scrubbers operate by introducing contaminated gas into a column where it comes
              into contact with a scrubbing liquid. This liquid, often combined with specific
              reagents, absorbs and neutralizes pollutants such as acid gases and other unwanted
              compounds. Our scrubbers feature advanced control mechanisms to ensure high removal
              efficiency and stable operation, with options for monitoring pH, conductivity,
              density, and other process parameters.
              <br />
              <br />
              Our scrubbers are suitable for a wide range of applications, effectively handling
              corrosive gases, hot exhaust streams, and dust-laden emissions. Common use cases
              include the removal of:
              <ul>
                <li>H₂S – removal in biogas upgrading and sulfur recovery units</li>
                <li>
                  H₂SO₄ mist – removal in sulfuric acid production or chemical processing plants
                </li>
                <li>
                  HCl – removal from metal pickling lines, incineration gas, or chemical
                  manufacturing exhaust
                </li>
                <li>
                  NH₃ – removal in fertilizer production, wastewater treatment, or animal rendering
                  plants
                </li>
                <li>VOCs – removal in pharmaceutical, paint, and solvent-based industries</li>
                <li>
                  SO₂ – removal in flue gas treatment from combustion or ore roasting operations
                </li>
              </ul>
              <br />
              Our gas scrubber systems are engineered to deliver high performance with low
              operational costs. If you are looking for a reliable and proven solution for air
              pollution control, our scrubbers offer the ideal combination of efficiency,
              durability, and cost-effectiveness.
            </S.Paragraph>
          </S.Column>
          <S.ImageContainer>
            <img src={carbonImage} alt="Carbon Scrubber Equipment" />
          </S.ImageContainer>
        </S.TwoColumnSection>

        <S.Section>
          <S.SectionTitle>Installation</S.SectionTitle>
          <S.Paragraph>
            We deliver turnkey gas scrubber installations, covering everything from design and
            engineering to manufacturing, on-site supervision, and commissioning. Each system is
            built for high removal efficiency and integrated into your process requirements.
          </S.Paragraph>
        </S.Section>

        <S.Section>
          <S.SectionTitle>Maintenance</S.SectionTitle>
          <S.Paragraph>
            Gas scrubbers are easy to operate and require minimal maintenance. Optional features
            like explosion protection (ATEX) and automated process controls support safe and
            efficient operation, with suggestions and guidance available for waste stream
            management.
          </S.Paragraph>
        </S.Section>

        <S.Section>
          <S.SectionTitle>Certifications and Compliance</S.SectionTitle>
          <S.Paragraph>
            All our gas scrubbers are engineered to comply with European industrial standards and
            can be customized for specific chemical resistance, temperature stability, and safety
            requirements
          </S.Paragraph>
        </S.Section>
        <S.TwoColumnSection>
          <S.Column>
            <S.SectionTitle>Process Design</S.SectionTitle>
            <S.List>
              <S.ListItem>Temperature Range: -10 to 200°C </S.ListItem>
              <S.ListItem>Flow Rates: Designed for 500 to 80,000 Nm³/h,</S.ListItem>
              <S.ListItem>Operating Pressure: -50 to +75 mbar,</S.ListItem>
              <S.ListItem>Pressure Drop: 10 – 40 mbar</S.ListItem>
              <S.ListItem>Efficiency: up to 99,9% removal efficiency</S.ListItem>
            </S.List>
          </S.Column>
          <S.Column>
            <S.SectionTitle>Materials</S.SectionTitle>
            <S.List>
              <S.ListItem>Carbon steel</S.ListItem>
              <S.ListItem>Stainless steel (316L, 304L)</S.ListItem>
            </S.List>
          </S.Column>
        </S.TwoColumnSection>
        <CardTechnology gridList data={gasData} />

        <S.ContainerTitles>
          <S.ProjectsHeading>Gas Scrubbers in projects</S.ProjectsHeading>
          <FilterCard />
          <FilterCard />

          <S.FilterCardButton onClick={() => alert("See more projects")}>
            View more projects
          </S.FilterCardButton>

          <S.MiniTitle>Our Partners</S.MiniTitle>
          <S.Title>
            We partner with industry leaders to create clean alternatives to traditional industrial
            processes
          </S.Title>
        </S.ContainerTitles>
        <Questions items={questionsData} />
        <CompanyCarousel />
        <GetInTouch />
      </S.Container>
      <Footer />
    </>
  );
}

export default Gas;
