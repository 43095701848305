import IconDu from "../../../assets/images/companies/IconDu.svg";
import IconForbo from "../../../assets/images/companies/IconForbo.svg";
import IconGrow from "../../../assets/images/companies/IconGrowHow.svg";
import IconLan from "../../../assets/images/companies/IconLanXess.svg";
import IconMecs from "../../../assets/images/companies/IconMecs.svg";
import IconNyn from "../../../assets/images/companies/IconNyr.svg";
import IconSteel from "../../../assets/images/companies/IconSteel.svg";
import IconTa from "../../../assets/images/companies/IconTa.svg";
import IconYara from "../../../assets/images/companies/IconYara.svg";

export const CompanyLogos = {
  GrowHow: {src: IconGrow, alt: "GrowHow"},
  Forbo: {src: IconForbo, alt: "Forbo"},
  LANXESS: {src: IconLan, alt: "LANXESS"},
  Nyrstar: {src: IconNyn, alt: "Nyrstar"},
  MECS: {src: IconMecs, alt: "MECS"},
  DuPont: {src: IconDu, alt: "DuPont"},
  Taqa: {src: IconTa, alt: "TAQA"},
  Yara: {src: IconYara, alt: "Yara"},
  TataSteel: {src: IconSteel, alt: "Tata Steel"},
};
