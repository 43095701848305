import {useContext, useEffect} from "react";
import {useLocation} from "react-router-dom";

import Breadcrumbs from "../../components/breadcrumbs";
import CompanyCarousel from "../../components/carousel";
import Footer from "../../components/footer";
import GetInTouch from "../../components/getInTouch";
import Header from "../../components/header";
import MiniCards from "../../components/miniCards";
import {GlobalContext} from "../../context/global/globalContext";
import * as S from "./styles";

function Industries() {
  const {setBreadcrumbs} = useContext(GlobalContext);
  const location = useLocation();

  useEffect(() => {
    setBreadcrumbs([
      {label: "Home", path: "/"},
      {label: "Industries", path: "/industries"},
    ]);
  }, [location, setBreadcrumbs]);

  return (
    <>
      <Header />
      <S.Container>
        <Breadcrumbs />
        <S.MainHeading>Industries</S.MainHeading>
        <S.Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris rhoncus enim a leo
          feugiat, non interdum sem condimentum. Quisque blandit lorem quis felis pellentesque
          luctus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac
          turpis egestas. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere
          cubilia curae; Proin lacinia nunc nisl, blandit fermentum quam rutrum sit amet. Fusce ut
          porta libero. Maecenas scelerisque eros id sem gravida faucibus. Orci varius natoque
          penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse at nisl
          dignissim, dictum erat semper, feugiat nulla. Mauris semper leo at nulla cursus venenatis.
          Pellentesque fermentum, lectus sed egestas consequat, lectus dui iaculis orci, ac
          imperdiet ligula mauris et turpis. In congue iaculis pretium. In ac diam aliquet,
          vestibulum mi non, finibus orci.
        </S.Paragraph>
        <MiniCards />
        <S.ContainerTitles>
          <S.MiniTitle>Our Partners</S.MiniTitle>
          <S.Title>
            We partner with industry leaders to create clean alternatives to <br />
            traditional industrial processes
          </S.Title>
        </S.ContainerTitles>
        <CompanyCarousel />
      </S.Container>
      <GetInTouch />
      <Footer />
    </>
  );
}

export default Industries;
