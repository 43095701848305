import {Button} from "@mui/material";
import styled from "styled-components";

import theme from "../../common/global/theme";
import fonts from "../../utils/fonts";

export const CustomButton = styled(Button)`
  && {
    width: ${({width}) => (width ? width : "116px")};
    max-width: 100%;
    height: ${({height}) => (height ? height : "36px")};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background-color: ${({backgroundColor}) =>
      backgroundColor ? backgroundColor : theme.colors.green};
    color: ${theme.colors.white};
    border-radius: 5px;
    border: ${({border}) => border};
    padding: ${({$padding}) => ($padding ? $padding : "0")};
    text-transform: none;
    ${fonts.lato.paragraph};
  }

  &:hover {
    background-color: ${({backgroundColorHover}) =>
      backgroundColorHover ? backgroundColorHover : theme.colors.green} !important;
  }
`;

export const CustomButtonWhite = styled(CustomButton)`
  background-color: transparent;
`;
