import styled from "styled-components";

import theme from "../../common/global/theme";
import fonts from "../../utils/fonts";
import {device} from "../../utils/mediaQueries";

export const Wrapper = styled.section`
  margin-top: 60px;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 40px;

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Card = styled.div`
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  background-color: ${theme.colors.white};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const Icon = styled.img`
  width: 48px;
  height: 48px;
  margin-bottom: 16px;
`;

export const CardTitle = styled.h3`
  ${fonts.lato.headingH4};
  color: ${theme.colors.black};
  margin-bottom: 8px;
`;

export const Link = styled.a`
  ${fonts.lato.paragraph};
  color: ${theme.colors.blue};
  text-decoration: underline;
  font-size: 14px;

  &:hover {
    color: ${theme.colors.primary};
  }
`;

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
  margin-top: 40px;
`;

export const ContactText = styled.p`
  ${fonts.lato.paragraph};
  align-items: center;
`;

export const ContactButton = styled.a`
  background-color: ${theme.colors.green};
  color: ${theme.colors.white};
  padding: 10px 24px;
  border-radius: 8px;
  font-weight: bold;
  text-decoration: none;
  transition: background 0.3s ease;

  &:hover {
    background-color: ${theme.colors.greenDark};
  }
`;
