import styled from "styled-components";

import theme from "../../common/global/theme";
import {device} from "../../utils/mediaQueries";
import fonts from "../../utils/fonts";

export const Container = styled.div`
  max-width: 1300px;
  margin: 40px auto;
  padding: 20px;

  @media ${device.mobile} {
    padding: 16px;
  }
`;

export const PageIntro = styled.div`
  text-align: center;
  margin-bottom: 40px;
`;

export const Title = styled.h1`
  ${fonts.lato.headingH1};
  color: #292929;
  margin-bottom: 16px;
`;

export const Subtitle = styled.h2`
  ${fonts.lato.headingH2};
  color: #292929;
  margin-bottom: 16px;
`;

export const Paragraph = styled.p`
  ${fonts.lato.paragraph};
  color: #333;
  margin-bottom: 16px;
  text-align: left;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
  margin-top: 48px;

  @media ${device.tablet} {
    max-width: 768px;
    margin: 0 auto;
  }

  @media ${device.mobile} {
    flex-direction: column;
    gap: 32px;
    margin-top: 32px;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 370px;
  flex: 1;

  @media ${device.tablet} {
    width: calc(50% - 12px);
    max-width: none;
  }

  @media ${device.mobile} {
    width: 100%;
    flex-direction: row;
    max-width: 100%;
  }

  &:last-child {
    @media ${device.tablet} {
      width: 100%;
    }
  }
`;

export const SideBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;

  @media ${device.mobile} {
    margin-right: 16px;
  }
`;

export const TopBar = styled.div`
  width: 15px;
  height: 90px;
  background-color: ${({color}) => color || theme.colors.green};
  border-radius: 8px;
`;

export const Divider = styled.div`
  width: 2px;
  height: 213px;
  background-color: #333;
  margin-top: 8px;

  @media ${device.mobile} {
    height: 200px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  height: 88px;
  margin-bottom: 16px;
`;

export const Icon = styled.img`
  width: 36px;
  height: 36px;
  object-fit: contain;
`;

export const CardTitle = styled.h3`
  ${fonts.lato.headingH3};
  color: ${({color}) => color || theme.colors.black};
`;

export const CardDescription = styled.p`
  ${fonts.lato.paragraph};
  color: ${theme.colors.gray};
`;
