import styled from "styled-components";

import fonts from "../../utils/fonts";
import {device} from "../../utils/mediaQueries";

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  box-sizing: border-box;
`;

export const Input = styled.input`
  width: 100%;
  max-width: 100%;
  padding: 14px 16px;
  border: 1px solid ${({theme}) => theme.colors.softGray};
  border-radius: 6px;
  ${fonts.lato.paragraph};
  background-color: #fff;
  color: ${({theme}) => theme.colors.black};

  &::placeholder {
    color: ${({theme}) => theme.colors.gray};
  }
`;

export const Textarea = styled.textarea`
  width: 100%;
  height: 160px;
  padding: 14px 16px;
  border: 1px solid ${({theme}) => theme.colors.softGray};
  border-radius: 6px;
  resize: none;
  ${fonts.lato.paragraph};
  background-color: #fff;
  color: ${({theme}) => theme.colors.black};

  &::placeholder {
    color: ${({theme}) => theme.colors.gray};
  }
`;

export const Button = styled.button`
  background-color: ${({theme}) => theme.colors.green};
  color: ${({theme}) => theme.colors.white};
  padding: 14px 24px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  ${fonts.lato.paragraphBold};
  transition: background 0.3s ease;

  &:hover {
    background-color: ${({theme}) => theme.colors.greenHover || theme.colors.green};
  }
`;

export const Row = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;

  @media ${device.mobile} {
    flex-direction: column;
    gap: 12px;
  }
`;

export const HalfInput = styled.input`
  width: calc(50% - 8px);
  padding: 14px 16px;
  border: 1px solid ${({theme}) => theme.colors.softGray};
  border-radius: 6px;
  ${fonts.lato.paragraph};
  background-color: #fff;
  color: ${({theme}) => theme.colors.black};

  &::placeholder {
    color: ${({theme}) => theme.colors.gray};
  }

  @media ${device.mobile} {
    width: 100%;
  }
`;

export const MapWrapper = styled.div`
  width: 100%;
  height: 300px;

  @media ${device.tablet} {
    height: 400px;
    max-width: 600px;
  }

  iframe {
    border: 0;
  }
`;
