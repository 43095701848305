import AboutUs from "../pages/aboutUs";
import Home from "../pages/home";
import Industries from "../pages/industries";
import Privacy from "../pages/Privacy";
import Projects from "../pages/project";
import OrganicPackage from "../pages/project/organicPackagePlant";
import InstallationService from "../pages/servicess/installationServices";
import Acid from "../pages/solutions/acid";
import VocOdourControl from "../pages/solutions/vocOdourControl";
import OffGasTreatment from "../pages/solutions/offGasTreatment";
import AcidFumes from "../pages/solutions/acidFumes";
import Fertilizer from "../pages/solutions/fertilizer";
import Desulphurisation from "../pages/solutions/desulphurisation";
import Hydrogen from "../pages/solutions/hydrogen";
import Organic from "../pages/solutions/organic";
import OrganicWaste from "../pages/solutions/organicWaste";
import Carbon from "../pages/technologies/carbon";
import Gas from "../pages/technologies/gas";
import Brink from "../pages/technologies/brinkMistEliminator";
import PlantInspections from "../pages/servicess/plantInspections";
import NonDestructiveTesting from "../pages/servicess/nonDestructiveTesting";
import LaserScanning from "../pages/servicess/3DLaserScanning";
import EmissionsMeasurements from "../pages/servicess/emissionsMeasurements";
import LaboratoryTesting from "../pages/servicess/laboratoryTesting";
import MeshPads from "../pages/technologies/meshPads";
import Sap from "../pages/technologies/sapTechnologies";

export const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
  //Solutions routes
  {
    path: "/desulphurisation",
    element: <Desulphurisation />,
    breadcrumb: "Desulphurisation",
  },
  {
    path: "/acid",
    element: <Acid />,
  },
  {
    path: "/vocodourcontrol",
    element: <VocOdourControl />,
  },
  {
    path: "/acidfumes",
    element: <AcidFumes />,
  },
  {
    path: "/organic",
    element: <Organic />,
  },
  {
    path: "/offgastreatment",
    element: <OffGasTreatment />,
  },
  {
    path: "/organic-waste",
    element: <OrganicWaste />,
  },
  {
    path: "/fertilizer",
    element: <Fertilizer />,
  },
  {
    path: "/hydrogen",
    element: <Hydrogen />,
  },
  //Industries
  {
    path: "/industries",
    element: <Industries />,
  },
  // Technologies routes
  {
    path: "/carbon",
    element: <Carbon />,
  },
  {
    path: "/meshpads",
    element: <MeshPads />,
  },
  {
    path: "/gas",
    element: <Gas />,
  },
  {
    path: "/brink",
    element: <Brink />,
  },
  {
    path: "/sap",
    element: <Sap />,
  },
  //Projects/OrganicPackage
  {
    path: "/organic-package",
    element: <OrganicPackage />,
  },
  //About us
  {
    path: "/aboutus",
    element: <AboutUs />,
  },
  //Projects
  {
    path: "/projects",
    element: <Projects />,
  },
  //Services/Installation Services
  {
    path: "/installation-services",
    element: <InstallationService />,
  },
  //Services/Plant Inspections
  {
    path: "/plant-inspections",
    element: <PlantInspections />,
  },
  //Services/Non-DestructiveTesting
  {
    path: "/non-destructive-testing",
    element: <NonDestructiveTesting />,
  },
  //Services/3DLaserScanning
  {
    path: "/laser-scanning",
    element: <LaserScanning />,
  },
  //Services/Emissions measurements
  {
    path: "/emissions-measurements",
    element: <EmissionsMeasurements />,
  },
  //Services/Laboratory Testing
  {
    path: "/laboratory-testing",
    element: <LaboratoryTesting />,
  },
];
