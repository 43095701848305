import React from "react";

import * as S from "./styles";
import ContactImage from "../../assets/images/pieter.png";
import Button from "../button";

function GetInTouch() {
  return (
    <S.Container>
      <S.ImageContainer>
        <S.Image src={ContactImage} alt="Contact" />
      </S.ImageContainer>
      <S.TextContainer>
        <S.Label href="">Get in touch</S.Label>
        <S.Heading>
          Let’s work together to build something great. Reach out today and let’s get started.
        </S.Heading>
        <Button text="Contact us" />
      </S.TextContainer>
    </S.Container>
  );
}

export default GetInTouch;
