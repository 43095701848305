import React from "react";

import carbonImage from "../../../assets/images/solutions/ImageSolutions.svg";
import CardTechnology from "../../../components/cardTechnology";
import CompanyCarousel from "../../../components/carousel";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import * as S from "./styles";
import GetInTouch from "../../../components/getInTouch";
import Questions from "../../../components/questions";

function Sap() {
  const mistData = [
    {
      id: "Filter",
      title: "Activated Wet Scrubbers",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris rhoncus enim a leo feugiat, non interdum sem condimentum. Quisque blandit lorem quis felis pellentesque luctus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Proin lacinia nunc nisl, blandit fermentum quam rutrum sit amet. Fusce ut porta libero. Maecenas scelerisque eros id sem gravida faucibus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse at nisl dignissim, dictum erat semper, feugiat nulla. Mauris semper leo at nulla cursus venenatis. ",
    },
    {
      id: "Filter",
      title: "Dry Scrubbers",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris rhoncus enim a leo feugiat, non interdum sem condimentum. Quisque blandit lorem quis felis pellentesque luctus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Proin lacinia nunc nisl, blandit fermentum quam rutrum sit amet. Fusce ut porta libero. Maecenas scelerisque eros id sem gravida faucibus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse at nisl dignissim, dictum erat semper, feugiat nulla. Mauris semper leo at nulla cursus venenatis. ",
    },
    {
      id: "Filter",
      title: "Eletroctatic Precipitators",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris rhoncus enim a leo feugiat, non interdum sem condimentum. Quisque blandit lorem quis felis pellentesque luctus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Proin lacinia nunc nisl, blandit fermentum quam rutrum sit amet. Fusce ut porta libero. Maecenas scelerisque eros id sem gravida faucibus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse at nisl dignissim, dictum erat semper, feugiat nulla. Mauris semper leo at nulla cursus venenatis. ",
    },
  ];
  const questionsData = [
    {
      question: "What technologies do you use for emission reduction?",
      answer:
        "<span style=\"font-family: 'Lato', sans-serif\">We utilize a range of technologies including advanced scrubbers, MECS® mist eliminators, mesh pads, activated carbon filters.</span>",
    },
    {
      question: "How can emission reduction solutions benefit my business?",
      answer:
        "<span style=\"font-family: 'Lato', sans-serif\">Our emission control systems help you meet environmental regulations, reduce pollutant output, and improve operational efficiency.</span>",
    },
  ];

  return (
    <>
      <Header />
      <S.Container>
        <S.MainHeading>SAP Technologies</S.MainHeading>
        <S.Paragraph>
          Ecosystems, in partnership with MECS Elessent for North-West Europe, offers comprehensive
          equipment and services for smelter off-gas cleaning and sulphuric acid production. With a
          strong presence across Europe, Ecosystems provides cutting-edge technologies for reliable,
          low-emission, and energy-efficient acid plant operations. We supply a broad range of
          high-performance systems, including catalyst supplies, mist eliminators, acid coolers, and
          tailgas scrubbers. Our solutions are engineered for safety, energy recovery, corrosion
          resistance, and long-term stability, ensuring compliance with stringent environmental
          standards.
        </S.Paragraph>

        <S.TwoColumnSection>
          <S.Column>
            <S.SectionTitle>How Gas Scrubbers Work</S.SectionTitle>
            <S.Paragraph>
              Ecosystems and MECS provide tailored equipment and process design solutions for new
              and revamped sulphuric acid plants. Our offering includes:
              <ul>
                <li>
                  Catalysts: Vanadium pentoxide types such as LP110, LP120, cesium-promoted SCX2000,
                  and the new Gear catalyst with 30% lower pressure drop or 25–30% higher SO₂
                  conversion.
                </li>
                <li>
                  Mist Eliminators: Patented high-efficiency mist eliminators, with optional Auto
                  Drain for safe maintenance and dry handling within 24 hours.
                </li>
                <li>
                  Uniflow Acid Distributors: Unique MECS design allowing reduced mist formation, 25%
                  lower pressure drop, and adjustable during commissioning.
                </li>
                <li>
                  Zecor™ Equipment: Acid towers and coolers made from MECS-grade Zecor™ alloys for
                  zero corrosion and consistent acid quality.
                </li>
                <li>
                  Tailgas Scrubbers: Available for all known process types, including H₂O₂, ammonia,
                  caustic, and seawater systems.
                </li>
                <li>
                  Process Instrumentation: SO₃ dewpoint meters, expansion joints, acid-resistant
                  repair kits to support plant safety and performance.
                </li>
              </ul>
              <br />
            </S.Paragraph>
          </S.Column>
          <S.ImageContainer>
            <img src={carbonImage} alt="Carbon Scrubber Equipment" />
          </S.ImageContainer>
        </S.TwoColumnSection>

        <S.Section>
          <S.SectionTitle>Installation</S.SectionTitle>
          <S.Paragraph>
            We offer turnkey installation services, covering engineering, design, on-site assembly,
            and commissioning. Each system is delivered with process design included, ensuring
            optimal integration and performance.
          </S.Paragraph>
        </S.Section>

        <S.Section>
          <S.SectionTitle>Maintenance</S.SectionTitle>
          <S.Paragraph>
            Our systems are built for safe, low-maintenance operation. Features like ATEX
            protection, automated drainage, and splash-free mist eliminators enhance safety and
            simplify handling.
          </S.Paragraph>
        </S.Section>

        <S.Section>
          <S.SectionTitle>Certifications and Compliance</S.SectionTitle>
          <S.Paragraph>
            All systems comply with European industrial standards and can be tailored for chemical
            resistance, temperature stability, and safety requirements.
          </S.Paragraph>
        </S.Section>
        <CardTechnology gridList data={mistData} />
        <S.ContainerTitles>
          <S.MiniTitle>Our Partners</S.MiniTitle>
          <S.Title>
            We partner with industry leaders to create clean alternatives to traditional industrial
            processes
          </S.Title>
        </S.ContainerTitles>
        <Questions items={questionsData} />
        <CompanyCarousel />
        <GetInTouch />
      </S.Container>
      <Footer />
    </>
  );
}

export default Sap;
