import React from "react";

import carbonImage from "../../../assets/images/solutions/ImageSolutions.svg";
import CardTechnology from "../../../components/cardTechnology";
import CompanyCarousel from "../../../components/carousel";
import Footer from "../../../components/footer";
import GetInTouch from "../../../components/getInTouch";
import Header from "../../../components/header";
import Questions from "../../../components/questions";
import * as S from "./styles";

function Carbon() {
  const carbonData = [
    {
      id: "Filter",
      title: "Activated Wet Scrubbers",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris rhoncus enim a leo feugiat, non interdum sem condimentum. Quisque blandit lorem quis felis pellentesque luctus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Proin lacinia nunc nisl, blandit fermentum quam rutrum sit amet. Fusce ut porta libero. Maecenas scelerisque eros id sem gravida faucibus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse at nisl dignissim, dictum erat semper, feugiat nulla. Mauris semper leo at nulla cursus venenatis. ",
    },
    {
      id: "Filter",
      title: "Dry Scrubbers",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris rhoncus enim a leo feugiat, non interdum sem condimentum. Quisque blandit lorem quis felis pellentesque luctus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Proin lacinia nunc nisl, blandit fermentum quam rutrum sit amet. Fusce ut porta libero. Maecenas scelerisque eros id sem gravida faucibus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse at nisl dignissim, dictum erat semper, feugiat nulla. Mauris semper leo at nulla cursus venenatis. ",
    },
    {
      id: "Filter",
      title: "Eletroctatic Precipitators",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris rhoncus enim a leo feugiat, non interdum sem condimentum. Quisque blandit lorem quis felis pellentesque luctus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Proin lacinia nunc nisl, blandit fermentum quam rutrum sit amet. Fusce ut porta libero. Maecenas scelerisque eros id sem gravida faucibus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse at nisl dignissim, dictum erat semper, feugiat nulla. Mauris semper leo at nulla cursus venenatis. ",
    },
  ];

  const questionsData = [
    {
      question: "What technologies do you use for emission reduction?",
      answer:
        "<span style=\"font-family: 'Lato', sans-serif\">We utilize a range of technologies including advanced scrubbers, MECS® mist eliminators, mesh pads, activated carbon filters.</span>",
    },
    {
      question: "How can emission reduction solutions benefit my business?",
      answer:
        "<span style=\"font-family: 'Lato', sans-serif\">Our emission control systems help you meet environmental regulations, reduce pollutant output, and improve operational efficiency.</span>",
    },
  ];

  return (
    <>
      <Header />
      <S.Container>
        <S.MainHeading>Activated Carbon Filter</S.MainHeading>
        <S.Paragraph>
          Activated carbon is a highly efficient filtration medium used to purify air, gas, and
          liquid streams across a wide range of industries. Thanks to its enormous internal surface
          area and adsorption capacity, activated carbon captures pollutants, odors, and harmful
          substances with exceptional performance and reliability. With its ability to adsorb
          volatile organic compounds (VOCs), H₂S, ammonia, and other contaminants, activated carbon
          is an essential solution for emission control, liquid purification, and odor treatment in
          both temporary and permanent setups.
        </S.Paragraph>

        <S.TwoColumnSection>
          <S.Column>
            <S.SectionTitle>How Activated Carbon Filters Work</S.SectionTitle>
            <S.Paragraph>
              Activated carbon is a processed form of charcoal, designed to maximize its internal
              surface area—up to 1000 m² per gram. This structure allows contaminants to become
              trapped in microscopic pores by a process called adsorption.
              <br />
              <br />
              As polluted air, gas, or liquid passes through an activated carbon bed, unwanted
              components such as VOCs, sulfur compounds, and colorants are adsorbed and held in
              place. Depending on the application, the carbon can be reactivated and reused, making
              the process sustainable and cost-efficient.
              <br />
              <br />
              Ecosystems Europe offers tailored activated carbon systems engineered for:
              <ul>
                <li>Air purification in industrial exhausts and odor control units</li>
                <li>Gas treatment, including H₂S and ammonia removal</li>
                <li>Liquid filtration, including water, process fluids, and solvents</li>
              </ul>
              <br />
              We supply carbon in bulk, in pre-loaded filter units, and in modular rental systems
              suitable for short-term or emergency use.
            </S.Paragraph>
            <S.TwoColumnSection>
              <S.Column>
                <S.SectionTitle>Process Design</S.SectionTitle>
                <S.List>
                  <S.ListItem>Temperature Range: -10 to 200°C </S.ListItem>
                  <S.ListItem>Flow Rates: Designed for 500 to 80,000 Nm³/h,</S.ListItem>
                  <S.ListItem>Operating Pressure: -50 to +75 mbar,</S.ListItem>
                  <S.ListItem>Pressure Drop: 10 – 40 mbar</S.ListItem>
                  <S.ListItem>Efficiency: up to 99,9% removal efficiency</S.ListItem>
                </S.List>
              </S.Column>
              <S.Column>
                <S.SectionTitle>Materials</S.SectionTitle>
                <S.List>
                  <S.ListItem>Carbon steel</S.ListItem>
                  <S.ListItem>Stainless steel (316L, 304L)</S.ListItem>
                </S.List>
              </S.Column>
            </S.TwoColumnSection>
          </S.Column>
          <S.ImageContainer>
            <img src={carbonImage} alt="Carbon Scrubber Equipment" />
          </S.ImageContainer>
        </S.TwoColumnSection>

        <CardTechnology gridList data={carbonData} />
        <S.ContainerTitles>
          <S.MiniTitle>Our Partners</S.MiniTitle>
          <S.Title>
            We partner with industry leaders to create clean alternatives to traditional industrial
            processes
          </S.Title>
        </S.ContainerTitles>
        <Questions items={questionsData} />
        <CompanyCarousel />
        <GetInTouch />
      </S.Container>
      <Footer />
    </>
  );
}

export default Carbon;
