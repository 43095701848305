import backgroundDesktop from "../../assets/images/numbers.png";
import {
  default as backgroundMobile,
  default as backgroundTablet,
} from "../../assets/images/numbers1.png";
import Button from "../button";
import * as S from "./styles";

function Numbers() {
  return (
    <S.SectionWrapper
      $backgroundDesktop={backgroundDesktop}
      $backgroundTablet={backgroundTablet}
      $backgroundMobile={backgroundMobile}
    >
      <S.ContentRight>
        <S.StatsGrid>
          <S.StatItem className="with-border">
            <S.StatValue>250+</S.StatValue>
            <S.StatLabel>Delivered projects</S.StatLabel>
          </S.StatItem>

          <S.StatItem className="with-border">
            <S.StatValue>200+</S.StatValue>
            <S.StatLabel>Provided services</S.StatLabel>
          </S.StatItem>

          <S.StatItem className="with-border">
            <S.StatValue>25+</S.StatValue>
            <S.StatLabel>Operations in different countries</S.StatLabel>
          </S.StatItem>

          <S.StatItem className="with-border">
            <S.StatValue>35+</S.StatValue>
            <S.StatLabel>Years of experience</S.StatLabel>
          </S.StatItem>
        </S.StatsGrid>

        <S.ActionsContainer>
          <S.JoinMessage>Join the count.</S.JoinMessage>
          <S.ButtonsWrapper>
            <Button text="Contact us" width="164px" />
            <Button text="Know more about us" white backgroundColor="transparent" width="164px" />
          </S.ButtonsWrapper>
        </S.ActionsContainer>
      </S.ContentRight>
    </S.SectionWrapper>
  );
}

export default Numbers;
