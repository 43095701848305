import styled from "styled-components";

import theme from "../../common/global/theme";
import fonts from "../../utils/fonts";
import {device} from "../../utils/mediaQueries";

export const Service = styled.section`
  text-align: center;
  padding: 80px 10%;
`;

export const Word = styled.h2`
  font-family: ${fonts.lato.paragraph.fontFamily};
  ${fonts.lato.paragraph}
  text-align: center;
  color: ${theme.colors.blue};
  margin: 0 auto 10px;

  @media ${device.tablet} {
    text-align: center;
  }
`;

export const Title = styled.h1`
  text-align: center;
  color: ${theme.colors.secondaryBlack};
  margin-bottom: 30px;
  ${fonts.lato.headingH2}
`;

export const CardServiceContainer = styled.div`
  display: grid;
  gap: 20px;
  justify-content: center;

  @media ${device.mobile} {
    grid-template-columns: 1fr;
  }

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1440px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const CardContainer = styled.div`
  text-align: left;
  padding: 20px;
`;

export const CardIcon = styled.img`
  width: 40px;
  height: 40px;
  margin-bottom: 16px;
`;

export const CardTitle = styled.h4`
  font-size: ${fonts.lato.headingH5.fontSize};
  margin-bottom: 8px;
  ${fonts.lato.headingH4}
`;

export const CardDescription = styled.p`
  font-size: ${fonts.lato.paragraph.fontSize};
  color: #0f172a;
  margin-bottom: 10px;
  ${fonts.roboto.paragraph}
`;

export const KnowMore = styled.a`
  font-size: ${fonts.lato.paragraph.fontSize};
  color: ${theme.colors.blue};
  text-decoration: none;
  ${fonts.lato.paragraph}

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const HireButton = styled.button`
  display: flex;
  width: 206px;
  height: 48px;
  padding: 16px 0;
  justify-content: center;
  align-items: center;
  margin: 50px auto 0;
  border-radius: 10px;
  border: none;
  background: ${theme.colors.green};
  cursor: pointer;
  font-size: ${fonts.lato.paragraph.fontSize};

  color: ${theme.colors.white};

  &:hover {
    background: ${theme.colors.darkBlack};
  }
`;

export const CardService = ({icon, title, description}) => {
  return (
    <CardContainer>
      <CardIcon src={icon} alt={title} />
      <CardTitle>{title}</CardTitle>
      <CardDescription>{description}</CardDescription>
      <KnowMore href="#">Know more</KnowMore>
    </CardContainer>
  );
};
