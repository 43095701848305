import React, {useEffect, useContext} from "react";

import Header from "../../../components/header";
import Questions from "../../../components/questions";
import CompanyCarousel from "../../../components/carousel";
import GetInTouch from "../../../components/getInTouch";
/*import Servicess from "../../../components/Servicess";*/
import Footer from "../../../components/footer";
import carbonImage from "../../../assets/images/solutions/ImageSolutions.svg";
import InstallationServicess from "../../../components/Servicess/installationServices";
import Breadcrumbs from "../../../components/breadcrumbs";
import * as S from "./styles";
import {GlobalContext} from "../../../context/global/globalContext";

const questionsData = [
  {
    question: "What technologies do you use for emission reduction?",
    answer:
      "<span style=\"font-family: 'Lato', sans-serif\">We utilize a range of technologies including advanced scrubbers, MECS® mist eliminators, mesh pads, activated carbon filters.</span>",
  },
  {
    question: "How can emission reduction solutions benefit my business?",
    answer:
      "<span style=\"font-family: 'Lato', sans-serif\">Our emission control systems help you meet environmental regulations, reduce pollutant output, and improve operational efficiency.</span>",
  },
];

function PlantInspections() {
  const {setBreadcrumbs} = useContext(GlobalContext);

  useEffect(() => {
    setBreadcrumbs([
      {label: "Home", path: "/"},
      {label: "Services", path: "/services"},
      {label: "Plant Inspections", path: "/services/plant-inspections"},
    ]);
  }, [setBreadcrumbs]);

  return (
    <>
      <Header />
      <S.PageContainer>
        <Breadcrumbs />

        <S.SectionTitle>Plant Inspections</S.SectionTitle>
        <S.Paragraph>
          We carry out pre-turnaround inspections on critical equipment, with a focus on mechanical
          integrity and current process conditions. Our inspections help identify wear, damage, or
          potential failures before they escalate. By detecting issues early, our experienced
          engineers help prevent costly unplanned downtime, reduce maintenance expenses, and support
          safer, more efficient shutdowns.
        </S.Paragraph>

        <S.TwoColumnSection>
          <S.Column>
            <S.SectionTitle>Applications</S.SectionTitle>
            <S.List>
              <S.ListItem>Drone and camera inspections </S.ListItem>
              <S.ListItem>Tank entries </S.ListItem>
              <S.ListItem>Catalyst & activated carbon sampling</S.ListItem>
              <S.ListItem>NDT inspections</S.ListItem>
            </S.List>
          </S.Column>
          <S.ImageContainer>
            <img src={carbonImage} alt="Carbon Scrubber Equipment" />
          </S.ImageContainer>
        </S.TwoColumnSection>

        <S.SectionTitle>What we offer</S.SectionTitle>
        <S.Paragraph>
          We provide inspections carried out by trained and experienced engineers, ensuring accurate
          assessments of your equipment’s condition. When necessary, our team can safely perform
          tank entries following all safety protocols. We also offer catalyst and activated carbon
          sampling and testing to support timely decision-making.h
        </S.Paragraph>

        <S.CenteredSection>
          <InstallationServicess />
        </S.CenteredSection>

        <S.SpacedBlock>
          <Questions items={questionsData} />
        </S.SpacedBlock>

        <S.Word>Our Services</S.Word>
        <S.Title>
          We partner with industry leaders to create clean alternatives to <br />
          traditional industrial processes
        </S.Title>

        <S.FullWidthSection>
          <CompanyCarousel />
        </S.FullWidthSection>
        <S.FullWidthSection>
          <GetInTouch />
        </S.FullWidthSection>
      </S.PageContainer>
      <Footer />
    </>
  );
}

export default PlantInspections;
