import styled from "styled-components";

import fonts from "../../utils/fonts";
import {device} from "../../utils/mediaQueries";

export const HeroContainer = styled.div`
  position: relative;
  height: 100vh;
`;

export const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 94vh;
  object-fit: cover;
  z-index: -1;
  filter: brightness(0.8);
`;

export const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  color: ${({theme}) => theme.colors.white};
  position: relative;
  padding: 16px;
  text-align: center;
`;

export const SectionContent = styled.div`
  max-width: 800px;
  z-index: 10;
`;

export const Title = styled.div`
  ${fonts.lato.headingH4}
  margin-bottom: 20px;
  color: ${({theme}) => theme.colors.blue};
`;

export const BigTitle = styled(Title)`
  ${fonts.lato.hero}
  color: ${({theme}) => theme.colors.white};
  white-space: nowrap;

  @media ${device.tablet}, ${device.mobile} {
    ${fonts.lato.headingH2}
    white-space: normal;
  }
`;

export const Slogan = styled.div`
  ${fonts.lato.paragraph}
  margin-bottom: 32px;
`;

export const AlignButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;
