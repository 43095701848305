import React from "react";

import Icon2 from "../../assets/images/candlestick_chart.png";
import Icon1 from "../../assets/images/construction.png";
import Icon3 from "../../assets/images/engineering.png";
import Icon6 from "../../assets/images/science.png";
import Icon4 from "../../assets/images/science_off.png";
import Icon5 from "../../assets/images/view_in_ar.png";
import * as S from "./styles";

function Servicess() {
  return (
    <S.Service>
      <S.Word>Our Services</S.Word>
      <S.Title>Available for all your maintenance needs</S.Title>
      <S.CardServiceContainer>
        <S.CardService
          icon={Icon1}
          title="Installation services"
          description="Posuere quis sed mauris non curabitur pretium elementum eget. Feugiat sed maecenas eu accumsan tristique."
        />
        <S.CardService
          icon={Icon3}
          title="Plant inspections"
          description="Sit bibendum donec dolor fames neque vulputate non sit aliquam. Consequat turpis natoque leo, massa."
        />
        <S.CardService
          icon={Icon4}
          title="Non-destructive testing"
          description="Congue mauris sem vel, urna viverra. Urna, nibh leo suscipit purus ut sed eros, consectetur viverra."
        />
        <S.CardService
          icon={Icon5}
          title="3D laser scanning"
          description="Platea arcu dapibus non magna cursus lectus id sollicitudin. Enim viverra parturient tristique nulla."
        />
        <S.CardService
          icon={Icon2}
          title="Emissions measurements"
          description="Platea arcu dapibus non magna cursus lectus id sollicitudin. Enim viverra parturient tristique nulla."
        />
        <S.CardService
          icon={Icon6}
          title="Laboratory testing"
          description="Congue mauris sem vel, urna viverra. Urna, nibh leo suscipit purus ut sed eros, consectetur viverra."
        />
      </S.CardServiceContainer>

      <S.HireButton>Hire us today</S.HireButton>
    </S.Service>
  );
}

export default Servicess;
