import {useNavigate} from "react-router-dom";

import * as S from "./styles";

function Footer() {
  const navigate = useNavigate();
  return (
    <S.FooterContainer>
      <S.FooterContent>
        <S.Column>
          <S.Logo />
          <S.Address>
            Ecosystems Europe BV
            <br />
            De Droogmakerij 20D
            <br />
            1851 LX HEILOO
            <br />
            The Netherlands
          </S.Address>
        </S.Column>

        <S.Column>
          <S.ContactInfo>
            <S.IconEmail />
            <S.StyledLink href="mailto:info@ecosystemseurope.com">
              info@ecosystemseurope.com
            </S.StyledLink>
          </S.ContactInfo>
          <S.ContactInfo>
            <S.IconPhone /> +31(0)72 8200 270
          </S.ContactInfo>
          <S.ContactInfo>
            <S.IconLinkedin />
            <S.StyledLink
              href="https://www.linkedin.com/company/ecosystems-europe-bv/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Follow on Linkedin
            </S.StyledLink>
          </S.ContactInfo>
        </S.Column>

        <S.Column>
          <S.FooterLink href="#">Industries</S.FooterLink>
          <S.FooterLink>Solutions</S.FooterLink>
          <S.FooterLink href="#">Services</S.FooterLink>
          <S.FooterLink href="#">Technologies</S.FooterLink>
          <S.FooterLink href="#">Projects</S.FooterLink>
        </S.Column>

        <S.Column>
          <S.FooterLink href="#">Contact us</S.FooterLink>
          <S.FooterLink href="#">About us</S.FooterLink>
        </S.Column>
      </S.FooterContent>

      <S.Separator />

      <S.FooterBottom>
        <S.Text>Ecosystems Europe, 2024.</S.Text>
        <S.Links>
          <S.FooterLink href="#">Cookie Policy</S.FooterLink>
          <S.FooterLink onClick={() => navigate("/privacy")}>Privacy Policy</S.FooterLink>
          <S.FooterLink href="#">Terms</S.FooterLink>
          {/* <S.LanguageSelector /> */}
        </S.Links>
      </S.FooterBottom>
    </S.FooterContainer>
  );
}

export default Footer;
