import {useContext} from "react";

import {GlobalContext} from "../../context/global/globalContext";
import * as S from "./styles";

function Breadcrumbs() {
  const {breadcrumbs} = useContext(GlobalContext);

  if (!breadcrumbs || breadcrumbs.length === 0) return null;

  return (
    <S.BreadcrumbNav>
      {breadcrumbs.map((crumb, index) => {
        const isLast = index === breadcrumbs.length - 1;

        return (
          <span key={crumb.path || index}>
            {!isLast ? (
              <>
                <S.CrumbLink to={crumb.path}>{crumb.label}</S.CrumbLink>
                <S.Separator>{">"}</S.Separator>
              </>
            ) : (
              <S.CrumbCurrent>{crumb.label}</S.CrumbCurrent>
            )}
          </span>
        );
      })}
    </S.BreadcrumbNav>
  );
}

export default Breadcrumbs;
