import {
  default as ImageFilter,
  default as ImageScrub,
} from "../../../assets/images/Imagefilter.svg";
import ImageCarbon from "../../../assets/images/technologies/ImageCarbon.svg";
import ImageDewpoint from "../../../assets/images/technologies/ImageDewpoint.svg";
import ImageMist from "../../../assets/images/technologies/ImageMist.svg";

export const TechnologyItems = {
  ActivatedCarbon: {
    src: ImageCarbon,
  },
  MistEliminators: {
    src: ImageMist,
  },
  GasScrubbers: {
    src: ImageScrub,
  },
  Dewpoint: {
    src: ImageDewpoint,
  },
  Filter: {
    src: ImageFilter,
  },
};
