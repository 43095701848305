import * as S from "./styles";

const ProjectInfo = ({data}) => {
  return (
    <S.PlantContainer>
      {data.map((item, index) => (
        <S.PlantItem key={index}>
          <S.TitleContainer>
            <S.Icon>{item.icon}</S.Icon>
            <S.PlantTitle>{item.title}</S.PlantTitle>
          </S.TitleContainer>
          <S.PlantDescription>{item.description}</S.PlantDescription>
        </S.PlantItem>
      ))}
    </S.PlantContainer>
  );
};

export default ProjectInfo;
