import emailjs from "emailjs-com";
import React, {useState} from "react";

import Button from "../../components/button";
import * as S from "./styles";

function ContactForm() {
  const [formData, setFormData] = useState({
    contactName: "",
    email: "",
    company: "",
    country: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        {
          contact_name: formData.contactName,
          email: formData.email,
          company: formData.company,
          country: formData.country,
          phone: formData.phone,
          message: formData.message,
        },
        process.env.REACT_APP_EMAILJS_USER_ID,
      )
      .then(
        () => alert("Message sent successfully!"),
        () => alert("Error sending message."),
      );
  };

  return (
    <S.Form onSubmit={handleSubmit}>
      <S.Input
        type="text"
        name="contactName"
        placeholder="Contact name"
        value={formData.contactName}
        onChange={handleChange}
        required
      />
      <S.Input
        type="email"
        name="email"
        placeholder="Email address"
        value={formData.email}
        onChange={handleChange}
        required
      />
      <S.Row>
        <S.HalfInput
          type="text"
          name="company"
          placeholder="Company"
          value={formData.company}
          onChange={handleChange}
        />
        <S.HalfInput
          type="text"
          name="country"
          placeholder="Country"
          value={formData.country}
          onChange={handleChange}
        />
      </S.Row>
      <S.Input
        type="tel"
        name="phone"
        placeholder="Contact phone"
        value={formData.phone}
        onChange={handleChange}
      />
      <S.Textarea
        name="message"
        placeholder="Message"
        value={formData.message}
        onChange={handleChange}
        required
      />
      <div style={{width: "100%"}}>
        <Button text="Submit" type="submit" width="100%" height="52px" style={{width: "100%"}} />
      </div>
    </S.Form>
  );
}

export default ContactForm;
