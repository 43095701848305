import theme from "../../common/global/theme";
import * as S from "./styles";

const SelectColorButton = {
  blue: {
    backgroundColor: theme.colors.blue,
    backgroundHover: theme.colors.blue,
    border: "none",
  },

  green: {
    backgroundColor: theme.colors.green,
    backgroundHover: theme.colors.green,
    border: "none",
  },

  transparent: {
    backgroundColor: "transparent",
    backgroundHover: "transparent",
    border: `1px solid ${theme.colors.white}`,
  },
};

function Button({text, type, width, height, backgroundColor, white}) {
  return (
    <>
      {!white ? (
        <S.CustomButton
          variant="text"
          type={type}
          width={width}
          height={height}
          backgroundColor={SelectColorButton[backgroundColor]?.backgroundColor}
          backgroundColorHover={SelectColorButton[backgroundColor]?.backgroundHover}
        >
          {text}
        </S.CustomButton>
      ) : (
        <S.CustomButtonWhite
          variant="text"
          type={type}
          width={width}
          height={height}
          border={SelectColorButton[backgroundColor]?.border}
          backgroundColor={SelectColorButton[backgroundColor]?.backgroundColor}
          backgroundColorHover={SelectColorButton[backgroundColor]?.backgroundHover}
        >
          {text}
        </S.CustomButtonWhite>
      )}
    </>
  );
}

export default Button;
