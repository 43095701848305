import solutionImage from "../../../assets/images/solutions/ImageSolutions.svg";
import CardTechnology from "../../../components/cardTechnology";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import Questions from "../../../components/questions";
import Carousel from "../../../components/carousel";
import GetInTouch from "../../../components/getInTouch";
import * as S from "./styles";

function OffGasTreatment() {
  const solutionsData = [
    {
      id: "ActivatedCarbon",
      title: "Activated Carbon",
      description: "Removes impurities from gases.",
    },
    {
      id: "MistEliminators",
      title: "Mist Eliminators",
      description: "Captures mist and fine droplets.",
    },
    {
      id: "GasScrubbers",
      title: "Gas Scrubbers",
      description: "Reduces pollutants in industrial exhaust.",
    },
    {
      id: "Dewpoint",
      title: "Dewpoint",
      description: "Measures moisture levels accurately.",
    },
  ];
  const questionsData = [
    {
      question: "What technologies do you use for emission reduction?",
      answer:
        "<span style=\"font-family: 'Lato', sans-serif\">We utilize a range of technologies including advanced scrubbers, MECS® mist eliminators, mesh pads, activated carbon filters.</span>",
    },
    {
      question: "How can emission reduction solutions benefit my business?",
      answer:
        "<span style=\"font-family: 'Lato', sans-serif\">Our emission control systems help you meet environmental regulations, reduce pollutant output, and improve operational efficiency.</span>",
    },
  ];
  return (
    <>
      <Header />
      <S.Container>
        <S.MainHeading>Off-Gas Treatment</S.MainHeading>
        <S.Paragraph>
          Ecosystems provides tailored solutions for engineered off-gas treatment across a wide
          range of industries. These systems are designed not only to meet strict emission limits,
          but also to improve energy efficiency, enable raw material recovery, and ensure full
          regulatory compliance. Our approach combines advanced scrubbing technologies with
          intelligent system integration—offering reliable, low-maintenance solutions for complex
          gas streams. Whether it is removing acid gases, organic vapours, or particulate matter,
          Ecosystems designs each installation with performance, safety, and long-term
          sustainability in mind.
        </S.Paragraph>

        <S.Section>
          <S.SectionTitle>Regulations and Safety</S.SectionTitle>
          <S.SubHeading>Environmental and Regulatory Standards</S.SubHeading>
          <S.Paragraph>
            Emission targets are defined by European legislation and BREF BAT guidelines. Ecosystems
            designs are fully adaptable to meet both international and local standards. Industrial
            Emissions Directive (IED) — Directive 2010/75/EU NEC Directive — Directive 2016/2284/EU
            Key Emission Limits — Based on BREF BAT conclusions Local Compliance — Additional
            national or site-specific requirements can be integrated on request
          </S.Paragraph>
          <S.SubHeading>Health and Safety</S.SubHeading>
          <S.Paragraph>
            All systems are developed with a strong focus on process safety, energy conservation,
            and resource recovery. Designs incorporate modern safety engineering while minimizing
            environmental impact. Example: Gas-Gas heat exchangers improve energy efficiency and
            reduce emissions Example: Use of H₂O₂ to convert SO₂ into sulphuric acid, eliminating
            sulphate-rich wastewaterNH₃ Ammonia Removal Recovered materials can be reintroduced into
            the process or reused, supporting sustainable operations and circular economy goals.
          </S.Paragraph>
        </S.Section>

        <S.ApplicationsSection>
          <S.ApplicationsList>
            <S.SectionTitle>Applications</S.SectionTitle>
            <S.List>
              <S.ListItem>Engineering and advisory boards</S.ListItem>
              <S.ListItem>Research facilities</S.ListItem>
              <S.ListItem>Third-party systems and OEM integration</S.ListItem>
            </S.List>
          </S.ApplicationsList>
          <S.ImageContainer>
            <img src={solutionImage} alt="Worker next to a flue gas desulfurization equipment" />
          </S.ImageContainer>
        </S.ApplicationsSection>

        <S.TwoColumnSection>
          <S.Column>
            <S.SectionTitle>Process Design</S.SectionTitle>
            <S.List>
              <S.ListItem>Temperature Range: -40 to 800°C </S.ListItem>
              <S.ListItem>Flow Rates: Designed for 500 to 800,000 Nm³/h,</S.ListItem>
              <S.ListItem>Operating Pressure: -100 to 1000 mbar,</S.ListItem>
              <S.ListItem>Pressure Drop: 10 – 80 mbar</S.ListItem>
              <S.ListItem>Efficiency: up to 99,9% removal efficiency</S.ListItem>
            </S.List>
          </S.Column>
          <S.Column>
            <S.SectionTitle>Materials</S.SectionTitle>
            <S.List>
              <S.ListItem>Carbon steel</S.ListItem>
              <S.ListItem>Stainless steel (316L, 304L, 904L, high-nickel alloys)</S.ListItem>
              <S.ListItem>FRP, HDPE, and PP</S.ListItem>
              <S.ListItem>linings of PP or PTFE in acidic enviroments</S.ListItem>
            </S.List>
          </S.Column>
        </S.TwoColumnSection>
      </S.Container>
      <CardTechnology data={solutionsData} />
      <Questions items={questionsData} />
      <Carousel />
      <GetInTouch />
      <Footer />
    </>
  );
}

export default OffGasTreatment;
