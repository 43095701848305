import styled from "styled-components";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import theme from "../../common/global/theme";
import {device} from "../../utils/mediaQueries";

export const CarouselContainer = styled.div`
  text-align: center;
  padding: 40px 0;
  background-color: ${theme.colors.white};
  cursor: pointer;
`;

export const LogoWrapper = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

export const Logo = styled.img`
  max-width: 120px;
  max-height: 60px;
  object-fit: contain;

  transition:
    filter 0.3s ease-in-out,
    transform 0.3s ease-in-out;

  &:hover {
    filter: grayscale(0%);
    transform: scale(1.05);
  }

  @media ${device.tablet} {
    max-width: 100px;
  }

  @media ${device.mobile} {
    max-width: 80px;
  }
`;
