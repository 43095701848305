import styled from "styled-components";

import theme from "../../common/global/theme";
import fonts from "../../utils/fonts";
import {device} from "../../utils/mediaQueries";

export const PageContainer = styled.div`
  max-width: 1150px;
  margin: 40px auto;
  padding: 20px;
`;

export const MainHeading = styled.h1`
  ${fonts.lato.headingH1};
  color: ${theme.colors.black};
  margin-bottom: 24px;
  text-align: center;

  @media ${device.mobile} {
    font-size: 32px;
  }
`;

export const Paragraph = styled.p`
  ${fonts.lato.paragraph};
  margin-bottom: 32px;
  color: ${theme.colors.gray};
  text-align: justify;
  max-width: 1150px;
  margin-left: auto;
  margin-right: auto;

  @media ${device.mobile} {
    font-size: 14px;
    padding: 0 16px;
  }
`;

export const FiltersContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 159px);
  row-gap: 16px;
  column-gap: 16px;
  margin: 0 auto 40px;
  justify-content: center;

  @media ${device.mobile} {
    grid-template-columns: repeat(2, 1fr);
    padding-left: 0;
  }
`;

export const FilterButton = styled.button`
  width: 159px;
  height: 30px;
  padding: 0 16px;
  background-color: ${({selected}) => (selected ? "#00ADEF" : theme.colors.white)};
  border: 1px solid ${theme.colors.lightGray};
  border-radius: 5px;
  color: ${({selected}) => (selected ? theme.colors.white : theme.colors.black)};
  font-family: "Lato", sans-serif;
  font-size: 14px;
  cursor: pointer;
  transition:
    background 0.3s ease,
    color 0.3s ease;

  &:hover {
    background-color: #00adef;
    color: ${theme.colors.white};
  }
`;

export const LoadMoreButton = styled.button`
  width: 160px;
  height: 48px;
  border-radius: 5px;
  background-color: ${theme.colors.blue};
  color: ${theme.colors.white};
  border: none;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin: 40px auto;
  display: block;
  transition: background 0.3s ease;

  &:hover {
    background-color: ${theme.colors.darkBlue};
  }
`;
