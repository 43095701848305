import React from "react";

import {GalleryItems} from "./constants/galleryData";
import * as S from "./styles";

function Gallery() {
  return (
    <S.GalleryContainer>
      <S.MiniTitle>Our Solutions</S.MiniTitle>
      <S.Title>Creating a cleaner, healthier future.</S.Title>
      <S.Grid>
        {Object.entries(GalleryItems).map(([key, item]) => (
          <S.Card key={key}>
            <S.Image src={item.src} alt={item.alt} />
            <S.TitleOverlay>{item.alt}</S.TitleOverlay>
            <S.HoverOverlay>
              <S.Text>{item.alt}</S.Text>
              <S.Description>
                Porttitor quis lectus varius quis dolor morbi massa pellentesque.
              </S.Description>
              <S.KnowMore href="#">Know more</S.KnowMore>
            </S.HoverOverlay>
          </S.Card>
        ))}
      </S.Grid>
    </S.GalleryContainer>
  );
}

export default Gallery;
