import solutionImage from "../../../assets/images/solutions/ImageSolutions.svg";
import CardTechnology from "../../../components/cardTechnology";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import Questions from "../../../components/questions";
import Carousel from "../../../components/carousel";
import GetInTouch from "../../../components/getInTouch";
import * as S from "./styles";

function vocOdourControl() {
  const solutionsData = [
    {
      id: "ActivatedCarbon",
      title: "Activated Carbon",
      description: "Removes impurities from gases.",
    },
    {
      id: "MistEliminators",
      title: "Mist Eliminators",
      description: "Captures mist and fine droplets.",
    },
    {
      id: "GasScrubbers",
      title: "Gas Scrubbers",
      description: "Reduces pollutants in industrial exhaust.",
    },
    {
      id: "Dewpoint",
      title: "Dewpoint",
      description: "Measures moisture levels accurately.",
    },
  ];
  const questionsData = [
    {
      question: "What technologies do you use for emission reduction?",
      answer:
        "<span style=\"font-family: 'Lato', sans-serif\">We utilize a range of technologies including advanced scrubbers, MECS® mist eliminators, mesh pads, activated carbon filters.</span>",
    },
    {
      question: "How can emission reduction solutions benefit my business?",
      answer:
        "<span style=\"font-family: 'Lato', sans-serif\">Our emission control systems help you meet environmental regulations, reduce pollutant output, and improve operational efficiency.</span>",
    },
  ];
  return (
    <>
      <Header />
      <S.Container>
        <S.MainHeading>VOC, Odour & H₂S Control</S.MainHeading>
        <S.Paragraph>
          Emissions of volatile organic compounds (VOCs), odorous vapours, and H₂S from industrial
          and waste-related processes are increasingly regulated due to their environmental impact
          and health risks. These pollutants contribute to smog formation, respiratory issues, and
          unpleasant working and living environments in surrounding areas. Ecosystems offers
          solutions for a wide range of industries and emission sources, focusing on high removal
          efficiency, odour abatement, and where possible, material recovery. By combining
          technologies such as activated carbon filtration, wet and dry scrubbing, and
          biofiltration, Ecosystems ensures not only compliance with strict air quality regulations
          but also operational reliability and reduced environmental footprint.
        </S.Paragraph>

        <S.Section>
          <S.SectionTitle>Regulations and Safety</S.SectionTitle>
          <S.SubHeading>Environmental and Regulatory Standards</S.SubHeading>
          <S.Paragraph>
            Industrial Emissions Directive (IED) — 2010/75/EU NEC Directive — 2016/2284/EU IED
            Revision — ongoing for 2024–2025 In some countries and U.S. states, aerosols are
            classified as dust, requiring emission limits of &lt 5 mg/Nm³—no issue for Ecosystems’
            high-efficiency Mist Eliminators.
          </S.Paragraph>
          <S.SubHeading>Health and Safety</S.SubHeading>
          <S.Paragraph>
            Uncontrolled emissions of VOCs and H₂S not only contribute to environmental harm but
            also pose serious risks to human health and operational safety. H₂S is both toxic and
            corrosive, requiring reliable removal methods, particularly in biogas and waste
            applications. Odorous vapours and organic compounds can significantly impact air
            quality, worker comfort, and community relations if not treated effectively. Thermal
            expansion and reactive fumes from chemical and polymer processing can contain hazardous
            compounds; Ecosystems designs robust systems to capture and neutralize these emissions.
          </S.Paragraph>
        </S.Section>

        <S.ApplicationsSection>
          <S.ApplicationsList>
            <S.SectionTitle>Applications</S.SectionTitle>
            <S.List>
              <S.ListItem>Waste treatment and recycling</S.ListItem>
              <S.ListItem>
                Biogas purification: Removal of H₂S and siloxanes using activated carbon or scrubber
                systems
              </S.ListItem>
              <S.ListItem>
                Tank loading and storage: Treatment of vapours during organic product loading
                operations
              </S.ListItem>
              <S.ListItem>
                Chemical synthesis and transport: Handling thermal expansion vapours and off-gas
                emissions
              </S.ListItem>
              <S.ListItem>
                Polymer production: Removal of oil mists, plasticizers, monomer vapours, and polymer
                emissions
              </S.ListItem>
            </S.List>
          </S.ApplicationsList>
          <S.ImageContainer>
            <img src={solutionImage} alt="Worker next to a flue gas desulfurization equipment" />
          </S.ImageContainer>
        </S.ApplicationsSection>

        <S.TwoColumnSection>
          <S.Column>
            <S.SectionTitle>Process Design</S.SectionTitle>
            <S.List>
              <S.ListItem>Temperature Range: 0 to 60 °C </S.ListItem>
              <S.ListItem>Flow Rates: Designed for 500 to 60,000 Nm³/h,</S.ListItem>
              <S.ListItem>Operating Pressure: -50 to +150 mbar,</S.ListItem>
              <S.ListItem>Pressure Drop: 10–60 mbar</S.ListItem>
              <S.ListItem>Efficiency: up to 99,9% removal efficiency</S.ListItem>
            </S.List>
          </S.Column>
          <S.Column>
            <S.SectionTitle>Materials</S.SectionTitle>
            <S.List>
              <S.ListItem>Carbon steel</S.ListItem>
              <S.ListItem>Stainless steel (316L, 304L)</S.ListItem>
              <S.ListItem>FRP</S.ListItem>
            </S.List>
          </S.Column>
        </S.TwoColumnSection>
      </S.Container>
      <CardTechnology data={solutionsData} />
      <Questions items={questionsData} />
      <Carousel />
      <GetInTouch />
      <Footer />
    </>
  );
}

export default vocOdourControl;
