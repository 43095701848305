const fonts = {
  roboto: {
    hero: {
      fontFamily: "Roboto",
      fontSize: "65px",
      fontWeight: "500",
      lineHeight: "71px",
      letterSpacing: "normal",
    },
    headingH1: {
      fontFamily: "Roboto",
      fontSize: "56px",
      fontWeight: "bold",
      lineHeight: "63px",
      letterSpacing: "normal",
    },
    headingH2: {
      fontFamily: "Roboto",
      fontSize: "48px",
      fontWeight: "bold",
      lineHeight: "56px",
      letterSpacing: "normal",
    },
    headingH3: {
      fontFamily: "Roboto",
      fontSize: "34px",
      fontWeight: "bold",
      lineHeight: "40px",
      letterSpacing: "normal",
    },
    headingH4: {
      fontFamily: "Roboto",
      fontSize: "24px",
      fontWeight: "bold",
      lineHeight: "30px",
      letterSpacing: "normal",
    },
    headingH5: {
      fontFamily: "Roboto",
      fontSize: "20px",
      fontWeight: "500",
      lineHeight: "26px",
      letterSpacing: "normal",
    },
    headingRegular: {
      fontFamily: "Roboto",
      fontSize: "20px",
      fontWeight: "Light",
      lineHeight: "30px",
      letterSpacing: "normal",
    },
    paragraph: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "24px",
      letterSpacing: "normal",
    },
    paragraphBold: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "bold",
      lineHeight: "24px",
      letterSpacing: "normal",
    },
    paragraphLight: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "300",
      lineHeight: "24px",
      letterSpacing: "normal",
    },
    small: {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "18px",
      letterSpacing: "normal",
    },
    label: {
      fontFamily: "Roboto",
      fontSize: "10px",
      fontWeight: "400",
      lineHeight: "16px",
      letterSpacing: "normal",
    },
  },

  lato: {
    hero: {
      fontFamily: "Lato",
      fontSize: "65px",
      fontWeight: "bold",
      lineHeight: "71px",
      letterSpacing: "0.12",
    },
    headingH1: {
      fontFamily: "Lato",
      fontSize: "56px",
      fontWeight: "bold",
      lineHeight: "63px",
      letterSpacing: "normal",
    },
    headingH2: {
      fontFamily: "Lato",
      fontSize: "48px",
      fontWeight: "bold",
      lineHeight: "56px",
      letterSpacing: "normal",
    },
    headingH3: {
      fontFamily: "Lato",
      fontSize: "34px",
      fontWeight: "bold",
      lineHeight: "40px",
      letterSpacing: "normal",
    },
    headingH4: {
      fontFamily: "Lato",
      fontSize: "24px",
      fontWeight: "medium",
      lineHeight: "30px",
      letterSpacing: "0.12",
    },
    headingH5: {
      fontFamily: "Lato",
      fontSize: "20px",
      fontWeight: "500",
      lineHeight: "26px",
      letterSpacing: "normal",
    },
    paragraph: {
      fontFamily: "Lato",
      fontSize: "16px",
      fontWeight: "regular",
      lineHeight: "24px",
      letterSpacing: "normal",
    },
    paragraphLight: {
      fontFamily: "Lato",
      fontSize: "16px",
      fontWeight: "100",
      lineHeight: "24px",
      letterSpacing: "normal",
    },
    paragraphBold: {
      fontFamily: "Lato",
      fontSize: "16px",
      fontWeight: "bold",
      lineHeight: "24px",
      letterSpacing: "normal",
    },
    small: {
      fontFamily: "Lato",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "18px",
      letterSpacing: "normal",
    },
    label: {
      fontFamily: "Lato",
      fontSize: "10px",
      fontWeight: "400",
      lineHeight: "16px",
      letterSpacing: "normal",
    },
  },
};

export default fonts;
