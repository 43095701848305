import React from "react";

import {TechnologyItems} from "./constants/technologyData";
import * as S from "./styles";

function CardTechnology({gridList = false, data}) {
  return (
    <S.Container>
      <S.GridContainer gridList={gridList}>
        {data.map((item, index) => (
          <React.Fragment key={index}>
            {gridList ? (
              <S.CardList>
                <S.ImageWrapper>
                  <S.Image src={TechnologyItems[item.id]?.src} alt={item.title} />
                </S.ImageWrapper>
                <S.ContentList>
                  <S.Title>{item.title}</S.Title>
                  <S.Description>{item.description}</S.Description>
                </S.ContentList>
              </S.CardList>
            ) : (
              <S.CardGrid>
                <S.Image src={TechnologyItems[item.id]?.src} alt={item.title} />
                <S.ContentGrid>
                  <S.Title>{item.title}</S.Title>
                  <S.Description>{item.description}</S.Description>
                  <S.KnowMore href="#">Know more</S.KnowMore>
                </S.ContentGrid>
              </S.CardGrid>
            )}
          </React.Fragment>
        ))}
      </S.GridContainer>
    </S.Container>
  );
}

export default CardTechnology;
