import {GoogleMap, InfoWindow, Marker, useJsApiLoader} from "@react-google-maps/api";
import React, {useCallback, useRef, useState} from "react";

const center = {
  lat: 52.60664,
  lng: 4.71428,
};

function GoogleMaps() {
  const mapRef = useRef(null);
  const [infoOpen, setInfoOpen] = useState(true);

  const {isLoaded} = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const onLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  return (
    <>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={{width: "100%", height: "100%"}}
          center={center}
          zoom={16}
          onLoad={onLoad}
        >
          <Marker position={center} onClick={() => setInfoOpen(true)} />
          {infoOpen && (
            <InfoWindow position={center} onCloseClick={() => setInfoOpen(false)}>
              <div>
                <h2>Ecosystems Europe BV</h2>
                <p>De Droogmakerij 20D</p>
                <p>1851 LX HEILOO, Netherlands</p>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      ) : (
        <p>Carregando mapa...</p>
      )}
    </>
  );
}

export default GoogleMaps;
