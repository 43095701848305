import styled from "styled-components";

import theme from "../../../common/global/theme";
import fonts from "../../../utils/fonts";
import {device} from "../../../utils/mediaQueries";

export const Container = styled.div`
  max-width: 1300px;
  margin: 40px auto;
  padding: 20px;
  background-color: ${theme.colors.white};
  color: ${theme.colors.black};
`;

export const MainHeading = styled.h1`
  ${fonts.lato.headingH1};
  color: ${theme.colors.black};
  margin-bottom: 24px;

  @media ${device.mobile} {
    font-size: 32px;
  }
`;

export const Section = styled.section`
  margin-bottom: 40px;
`;

export const SectionTitle = styled.h2`
  ${fonts.lato.headingH2};
  margin-bottom: 16px;

  @media ${device.mobile} {
    font-size: 28px;
  }
`;

export const SubHeading = styled.h3`
  ${fonts.lato.headingH4};
  margin-bottom: 12px;

  @media ${device.mobile} {
    font-size: 20px;
  }
`;

export const Paragraph = styled.p`
  ${fonts.lato.paragraph};
  margin-bottom: 16px;
  color: ${theme.colors.gray};

  @media ${device.mobile} {
    font-size: 14px;
  }
`;

/* Seção Applications corrigida */
export const ApplicationsSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  margin-bottom: 40px;

  @media ${device.tablet} {
    display: flex; /* Mantém alinhado igual ao mobile */
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 30px;
  }

  @media ${device.mobile} {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
    gap: 20px;
  }
`;

export const ApplicationsList = styled.div`
  flex: 1;

  @media ${device.tablet} {
    width: 100%;
    text-align: left;
    padding: 0 40px; /* Melhor espaçamento no tablet */
  }

  @media ${device.mobile} {
    width: 100%;
    text-align: left;
    padding: 0 20px;
  }
`;

export const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  margin-right: 0;

  img {
    width: 100%;
    max-width: 600px;
    height: auto;
    display: block;
  }

  @media ${device.tablet} {
    justify-content: center;
    max-width: 600px;
  }

  @media ${device.mobile} {
    width: 100%;
    justify-content: center;

    img {
      max-width: 350px;
    }
  }
`;

export const TwoColumnSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
  align-items: start;
  margin-bottom: 40px;

  @media ${device.tablet} {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  @media ${device.mobile} {
    flex-direction: column;
    gap: 20px;
  }
`;

export const Column = styled.div`
  flex: 1;

  @media ${device.tablet} {
    width: 100%;
    text-align: left;
  }

  @media ${device.mobile} {
    width: 100%;
  }
`;

export const List = styled.ul`
  list-style-type: none;
  padding-left: 0;
`;

export const ListItem = styled.li`
  ${fonts.lato.paragraph};
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  &::before {
    content: "▪";
    color: ${theme.colors.blue};
    font-size: 40px;
    margin-right: 8px;
  }

  @media ${device.mobile} {
    justify-content: left;
  }
`;
