import styled from "styled-components";

import theme from "../../common/global/theme";
import fonts from "../../utils/fonts";
import {device} from "../../utils/mediaQueries";

export const GalleryContainer = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: auto;
  padding: 20px;
  position: relative;
`;

export const PhotoCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;

export const Photo = styled.img`
  width: 100%;
  max-width: 300px;
  border-radius: 8px;

  @media ${device.mobile} {
    max-width: 90%;
  }
`;

export const PhotoDescription = styled.p`
  ${fonts.roboto.paragraphLight};
  margin-top: 10px;
  color: ${theme.colors.gray};
  text-align: start;
`;

export const ArrowButton = styled.button`
  background: #00adef;
  color: ${theme.colors.white};
  border: none;
  border-radius: 50%;
  font-size: 18px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  opacity: 0.8;
  transition: 0.3s;

  ${({left}) => left && `left: -40px;`}
  ${({right}) => right && `right: -40px;`}

  &:hover {
    opacity: 1;
  }

  svg {
    font-size: 16px;
  }

  @media ${device.mobile} {
    width: 28px;
    height: 28px;
    font-size: 14px;
    ${({left}) => left && `left: -20px;`}
    ${({right}) => right && `right: -20px;`}
  }
`;
