import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import Button from "../button";
import * as S from "./styles";

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigation = (route) => {
    navigate(route);
  };

  const menuItems = [
    {
      title: "Solutions",
      options: [
        {name: "Acid Fumes & Inorganics", path: "/acidfumes"},
        {name: "Acid Mist and Aerosols", path: "/acid"},
        {name: "Desulphurisation", path: "/desulphurisation"},
        {name: "Off Gas Treatment", path: "/offgastreatment"},
        {name: "Organic Mists & Aerosols", path: "/organic"},
        {name: "VOC, Odour & H₂S Control", path: "/vocodourcontrol"},
        /*{name: "Organic waste odour filtration", path: "/organic-waste"},*/
        /*{name: "Fertilizer Ammonium salt fume separation", path: "/fertilizer"},*/
        /*{name: "Hydrogen and Chlorine process gas upgrading", path: "/hydrogen"},*/
      ],
    },
    {
      title: "Technologies",
      options: [
        {name: "Actived Carbon", path: "/carbon"},
        {name: "Mist Eliminators", path: "/brink"},
        {name: "Gas Scrubbers", path: "/gas"},
        {name: "Mesh Pads", path: "/meshpads"},
        {name: "SAP Technologies", path: "/sap"},
      ],
    },
    {
      title: "Services",
      options: [
        {name: "Installation Services", path: "/installation-services"},
        {name: "Plant Inspections", path: "/plant-inspections"},
        {name: "Non-DestructiveTesting", path: "/non-destructive-testing"},
        {name: "3D Laser Scanning", path: "/laser-scanning"},
        {name: "Emissions measurements", path: "/emissions-measurements"},
        {name: "Laboratory testing", path: "/laboratory-testing"},
      ],
    },
    /*{
      title: "Projects",
      path: "/projects",
      options: [
        {name: "Organic Package Plant", path: "/organic-package"},
        {name: "Project 2"},
        {name: "Project 3"},
      ],
    }*/
  ];

  return (
    <>
      <S.HeaderContainer isSticky={isSticky}>
        <S.TopBar isSticky={isSticky}>
          <S.Logo isSticky={isSticky} onClick={() => navigate("/")} />
          <S.NavWrapper>
            <S.CollapsibleMenu isOpen={isOpen}>
              <S.MenuItem>
                {menuItems[0].title}
                <S.Seta />
                <S.DropdownMenu>
                  {menuItems[0].options.map((option, idx) => (
                    <S.Ancora key={idx} onClick={() => handleNavigation(option.path)}>
                      {option.name}
                    </S.Ancora>
                  ))}
                </S.DropdownMenu>
              </S.MenuItem>

              {/*<S.MenuItem onClick={() => handleNavigation("/industries")}>Industries</S.MenuItem>*/}

              {menuItems.slice(1).map((menu, index) => (
                <S.MenuItem key={index}>
                  <span
                    onClick={() => menu.path && handleNavigation(menu.path)}
                    style={{cursor: menu.path ? "pointer" : "default"}}
                  >
                    {menu.title}
                  </span>
                  <S.Seta />
                  <S.DropdownMenu>
                    {menu.options.map((option, idx) =>
                      option.path ? (
                        <S.Ancora key={idx} onClick={() => handleNavigation(option.path)}>
                          {option.name}
                        </S.Ancora>
                      ) : (
                        <S.Ancora key={idx}>{option.name}</S.Ancora>
                      ),
                    )}
                  </S.DropdownMenu>
                </S.MenuItem>
              ))}
              <S.MenuItem onClick={() => navigate("/aboutus")}>About us</S.MenuItem>
            </S.CollapsibleMenu>
          </S.NavWrapper>
          <S.AlignItems>
            {/*
            <S.Language />
            */}
            <S.HideOnMobile>
              <Button text="Get in touch" />
            </S.HideOnMobile>
            <S.MenuIcon onClick={toggleMenu} />
          </S.AlignItems>
        </S.TopBar>
      </S.HeaderContainer>
    </>
  );
}

export default Header;
