import styled from "styled-components";

import theme from "../../../common/global/theme";
import fonts from "../../../utils/fonts";
import {device} from "../../../utils/mediaQueries";

export const PageContainer = styled.div`
  max-width: 1300px;
  margin: 40px auto;
  padding: 20px;
  background-color: ${theme.colors.white};
  color: ${theme.colors.black};

  @media ${device.mobile} {
    padding: 10px;
  }
`;

export const MainHeading = styled.h1`
  ${fonts.lato.headingH1};
  color: ${theme.colors.black};
  margin-bottom: 8px;

  @media ${device.mobile} {
    font-size: 28px;
  }
`;

export const SubHeading = styled.h3`
  ${fonts.lato.paragraph};
  color: ${theme.colors.gray};
  margin-bottom: 24px;
`;

export const Paragraph = styled.p`
  ${fonts.lato.paragraph};
  margin-bottom: 16px;
  color: ${theme.colors.gray};

  @media ${device.mobile} {
    font-size: 14px;
  }
`;

export const SectionTitle = styled.h2`
  ${fonts.lato.headingH2};
  margin-top: 40px;
  margin-bottom: 16px;

  @media ${device.mobile} {
    font-size: 24px;
  }
`;

export const PlantContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 40px;

  @media ${device.tablet} {
    justify-content: center;
  }

  @media ${device.mobile} {
    flex-direction: column;
    align-items: center;
  }
`;

export const PlantItem = styled.div`
  flex: 1;
  min-width: 200px;
  max-width: 250px;
  text-align: center;

  @media ${device.mobile} {
    max-width: 100%;
  }
`;

export const Icon = styled.div`
  font-size: 24px;
  margin-bottom: 8px;
`;

export const PlantTitle = styled.h4`
  ${fonts.lato.headingH5};
  color: ${theme.colors.black};
  margin-bottom: 4px;
`;

export const PlantDescription = styled.p`
  ${fonts.lato.paragraph};
  color: ${theme.colors.gray};
`;

export const GalleryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;

  @media ${device.tablet} {
    flex-wrap: wrap;
    justify-content: center;
  }

  @media ${device.mobile} {
    flex-direction: column;
    align-items: center;
  }
`;

export const PhotoCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;

  @media ${device.mobile} {
    max-width: 100%;
  }
`;

export const Photo = styled.img`
  width: 100%;
  max-width: 300px;
  border-radius: 8px;

  @media ${device.mobile} {
    max-width: 90%;
  }
`;

export const PhotoDescription = styled.p`
  margin-top: 10px;
  font-size: 14px;
  color: ${theme.colors.gray};
  text-align: center;
`;
