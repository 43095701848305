import heroVideo from "../../assets/images/hero.mp4";
import Button from "../button";
import * as S from "./styles";

function Hero() {
  return (
    <S.HeroContainer>
      <S.VideoBackground autoPlay loop muted>
        <source src={heroVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </S.VideoBackground>
      <S.Section>
        <S.SectionContent>
          <S.Title>Gas treatment experts</S.Title>
          <S.BigTitle>We help clean the environment</S.BigTitle>
          <S.Slogan>
            Design, construction, installation and start-up of hyper-modern technologies based on
            <br />
            proven technologies, for a better environment and clean air.
          </S.Slogan>
          <S.AlignButtons>
            <Button text="Our Solutions" backgroundColor="blue" width="148px" />
            <Button text="Our Services" white backgroundColor="transparent" width="148px" />
          </S.AlignButtons>
        </S.SectionContent>
      </S.Section>
    </S.HeroContainer>
  );
}

export default Hero;
