const size = {
  mobileMax: "768px",
  tabletMin: "769px",
  tabletMax: "1024px",
};

export const device = {
  mobile: `(max-width: ${size.mobileMax})`,
  tablet: `(min-width: ${size.tabletMin}) and (max-width: ${size.tabletMax})`,
};
