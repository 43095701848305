import React from "react";

import * as S from "./styles";
import IndustryIcon from "../../assets/images/filterCard/industry.png";
import ActivityIcon from "../../assets/images/filterCard/activity.png";
import TechIcon from "../../assets/images/filterCard/technology.png";
import FlowIcon from "../../assets/images/filterCard/flow.png";
import TempIcon from "../../assets/images/filterCard/temperature.png";
import SmokeImage from "../../assets/images/filterCard/smoke.png";

function FilterCard({
  image = SmokeImage,
  title = "Organic Package Plant",
  location = "Heerhugowaard, Netherlands",
  date = "February, 2023",
  industry = "PVC Coating/Flooring",
  activity = "Plasticizer fumes/ Odor Control",
  technology = "Mist Eliminators",
  flow = "Am3/hr",
  temperature = "°C",
  onDetailsClick = () => alert("View details clicked"),
}) {
  return (
    <S.CardWrapper>
      <S.Image src={image} alt={title} />
      <S.Content>
        <S.Header>
          <S.Title>{title}</S.Title>
          <S.SubInfo>
            {location} – {date}
          </S.SubInfo>
        </S.Header>

        <S.InfoGrid>
          <S.InfoItem>
            <S.Icon src={IndustryIcon} alt="Industry" />
            <S.InfoText>
              <S.Label>Industry</S.Label>
              <S.Value>{industry}</S.Value>
            </S.InfoText>
          </S.InfoItem>

          <S.InfoItem>
            <S.Icon src={ActivityIcon} alt="Activity" />
            <S.InfoText>
              <S.Label>Activity</S.Label>
              <S.Value>{activity}</S.Value>
            </S.InfoText>
          </S.InfoItem>

          <S.InfoItem>
            <S.Icon src={TechIcon} alt="Technology" />
            <S.InfoText>
              <S.Label>Technology</S.Label>
              <S.Value>{technology}</S.Value>
            </S.InfoText>
          </S.InfoItem>

          <S.InfoItem>
            <S.Icon src={FlowIcon} alt="Flow" />
            <S.InfoText>
              <S.Label>Flow</S.Label>
              <S.Value>{flow}</S.Value>
            </S.InfoText>
          </S.InfoItem>

          <S.InfoItem>
            <S.Icon src={TempIcon} alt="Temperature" />
            <S.InfoText>
              <S.Label>Temperature</S.Label>
              <S.Value>{temperature}</S.Value>
            </S.InfoText>
          </S.InfoItem>
        </S.InfoGrid>

        <S.DetailsLink onClick={onDetailsClick}>View details</S.DetailsLink>
      </S.Content>
    </S.CardWrapper>
  );
}

export default FilterCard;
