import styled from "styled-components";

import theme from "../../common/global/theme";
import fonts from "../../utils/fonts";

export const Container = styled.div`
  max-width: 900px;
  margin: 40px auto;
  padding: 20px;
  background-color: ${theme.colors.white};
  color: ${theme.colors.black};
`;

export const Title = styled.h1`
  ${fonts.lato.headingH1};
  margin-bottom: 20px;
`;

export const SubTitle = styled.h3`
  ${fonts.lato.headingH3};
  margin-top: 30px;
  margin-bottom: 10px;
`;

export const Paragraph = styled.p`
  ${fonts.lato.paragraph};
  color: ${theme.colors.gray};
  margin-bottom: 10px;
  line-height: 1.6;
`;

export const Section = styled.section`
  margin-bottom: 30px;
`;

export const ContactInfo = styled.div`
  ${fonts.lato.paragraph};
  color: ${theme.colors.gray};
  margin-top: 10px;

  p {
    margin: 5px 0;
  }
`;
