import ImageAcid from "../../../assets/images/solutions/ImageAcid.svg";
import ImageAlkaline from "../../../assets/images/solutions/ImageAlkaline.svg";
import ImageBitumen from "../../../assets/images/solutions/ImageBitumen.svg";
import ImageFertilizer from "../../../assets/images/solutions/ImageFertilizer.svg";
import ImageFlueGas from "../../../assets/images/solutions/ImageFlueGas.svg";
import ImageFlume from "../../../assets/images/solutions/ImageFlume.svg";
import ImageHydrogen from "../../../assets/images/solutions/ImageHydrogen.svg";
import ImageOrganic from "../../../assets/images/solutions/ImageOrganic.svg";
import ImageVapour from "../../../assets/images/solutions/ImageVapour.svg";

export const GalleryItems = {
  FlueGasDesulfurization: {src: ImageFlueGas, alt: "Flue gas desulfurization"},
  AcidEmissionMistRemoval: {src: ImageAcid, alt: "Acid emission mist removal"},
  AlkalineAmmoniaAbatement: {src: ImageAlkaline, alt: "Alkaline and Ammonia abatement"},
  ChemicalFumeReduction: {src: ImageFlume, alt: "Chemical Fume reduction"},
  OrganicVapourAbsorption: {src: ImageVapour, alt: "Organic vapour absorption"},
  BitumenFumeControl: {src: ImageBitumen, alt: "Bitumen fume control"},
  OrganicWasteOdourFiltration: {src: ImageOrganic, alt: "Organic waste odour filtration"},
  FertilizerAmmoniumSaltFumeSeparation: {
    src: ImageFertilizer,
    alt: "Fertilizer Ammonium salt fume separation",
  },
  HydrogenAndChlorineProcessGas: {
    src: ImageHydrogen,
    alt: "Hydrogen and Chlorine process",
  },
};
