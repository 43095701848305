import {createRoot} from "react-dom/client";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {ThemeProvider} from "styled-components";

import theme from "./common/global/theme";
import {GlobalProvider} from "./context/global/globalContext";
import {routes} from "./routes/routes";

const router = createBrowserRouter(routes);

createRoot(document.getElementById("root")).render(
  <ThemeProvider theme={theme}>
    <GlobalProvider>
      <RouterProvider router={router} />
    </GlobalProvider>
  </ThemeProvider>,
);
