import solutionImage from "../../../assets/images/solutions/ImageSolutions.svg";
import CardTechnology from "../../../components/cardTechnology";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import Questions from "../../../components/questions";
import Carousel from "../../../components/carousel";
import GetInTouch from "../../../components/getInTouch";
import * as S from "./styles";

function Organic() {
  const solutionsData = [
    {
      id: "ActivatedCarbon",
      title: "Activated Carbon",
      description: "Removes impurities from gases.",
    },
    {
      id: "MistEliminators",
      title: "Mist Eliminators",
      description: "Captures mist and fine droplets.",
    },
    {
      id: "GasScrubbers",
      title: "Gas Scrubbers",
      description: "Reduces pollutants in industrial exhaust.",
    },
    {
      id: "Dewpoint",
      title: "Dewpoint",
      description: "Measures moisture levels accurately.",
    },
  ];
  const questionsData = [
    {
      question: "What technologies do you use for emission reduction?",
      answer:
        "<span style=\"font-family: 'Lato', sans-serif\">We utilize a range of technologies including advanced scrubbers, MECS® mist eliminators, mesh pads, activated carbon filters.</span>",
    },
    {
      question: "How can emission reduction solutions benefit my business?",
      answer:
        "<span style=\"font-family: 'Lato', sans-serif\">Our emission control systems help you meet environmental regulations, reduce pollutant output, and improve operational efficiency.</span>",
    },
  ];
  return (
    <>
      <Header />
      <S.Container>
        <S.MainHeading>Flue Gas Desulfurization</S.MainHeading>
        <S.Paragraph>
          Organic aerosols are often formed through the condensation of high-boiling solvents in
          atmospheric conditions. With over 35 years of experience, Ecosystems provides effective
          solutions for reducing and eliminating these emissions. What began as simple opacity
          control and odour removal has evolved into state-of-the-art mist elimination—achieving
          emissions nearly down to zero. In line with the EU Green Deal and Zero Pollution Action
          Plan, Ecosystems solutions already outperform current regulatory targets. Mist Eliminators
          reduce VOC emissions from various industrial processes to well below 5 mg/Nm³—a reduction
          of over 90% compared to 2005 levels.
        </S.Paragraph>

        <S.Section>
          <S.SectionTitle>Regulations and Safety</S.SectionTitle>
          <S.SubHeading>Environmental and Regulatory Standards</S.SubHeading>
          <S.Paragraph>
            Environmental and Regulatory Standards Industrial Emissions Directive (IED) — 2010/75/EU
            NEC Directive — 2016/2284/EU IED Revision — ongoing for 2024–2025 In some countries and
            U.S. states, aerosols are classified as dust, requiring emission limits of 5 mg/Nm³—no
            issue for Ecosystems’ high-efficiency Mist Eliminators.
          </S.Paragraph>
          <S.SubHeading>Health and Safety</S.SubHeading>
          <S.Paragraph>
            Aerosol emissions from plasticizers or bitumen fumes often carry unpleasant odours.
            These odours are absorbed onto fine organic particles and drift downwind with the plume.
            Removing the aerosol plume also removes the odour. For PVC coatings, no extra odour
            control is needed. For bitumen vapours, downstream activated carbon filters may be
            required due to sulphur compounds. Mist elimination upstream helps extend the lifetime
            of these filters.
          </S.Paragraph>
        </S.Section>

        <S.ApplicationsSection>
          <S.ApplicationsList>
            <S.SectionTitle>Applications</S.SectionTitle>
            <S.List>
              <S.ListItem>
                Removal of plasticizers (phthalates, benzoates) from PVC, PU, and PP coatings and
                molding operations
              </S.ListItem>
              <S.ListItem>
                High-flow applications like flooring production, wallpaper, and film extrusion
              </S.ListItem>
              <S.ListItem>
                Treatment of bitumen vapours in asphalt, roofing, and carpet manufacturing
              </S.ListItem>
              <S.ListItem>VOC reduction from monomer/polymer production</S.ListItem>
              <S.ListItem>Control of amines, tall oil, and lubricant aerosols</S.ListItem>
            </S.List>
          </S.ApplicationsList>
          <S.ImageContainer>
            <img src={solutionImage} alt="Worker next to a flue gas desulfurization equipment" />
          </S.ImageContainer>
        </S.ApplicationsSection>

        <S.TwoColumnSection>
          <S.Column>
            <S.SectionTitle>Process Design</S.SectionTitle>
            <S.List>
              <S.ListItem>Temperature Range: -10 to 200 °C </S.ListItem>
              <S.ListItem>Flow Rates: Designed for 500 to 80,000 Nm³/h,</S.ListItem>
              <S.ListItem>Operating Pressure: -50 to +75 mbar,</S.ListItem>
              <S.ListItem>Pressure Drop: 10 – 40 mbar</S.ListItem>
              <S.ListItem>Efficiency: up to 99,9% removal efficiency</S.ListItem>
            </S.List>
          </S.Column>
          <S.Column>
            <S.SectionTitle>Materials</S.SectionTitle>
            <S.List>
              <S.ListItem>Carbon steel</S.ListItem>
              <S.ListItem>Stainless steel (316L, 304L)</S.ListItem>
            </S.List>
          </S.Column>
        </S.TwoColumnSection>
      </S.Container>
      <CardTechnology data={solutionsData} />
      <Questions items={questionsData} />
      <Carousel />
      <GetInTouch />
      <Footer />
    </>
  );
}

export default Organic;
