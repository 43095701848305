import React from "react";
import Slider from "react-slick";

import {CompanyLogos} from "./constants/companyLogos";
import * as S from "./styles";

function CompanyCarousel() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <S.CarouselContainer>
      <Slider {...settings}>
        {Object.entries(CompanyLogos).map(([key, logo]) => (
          <S.LogoWrapper key={key}>
            <S.Logo src={logo.src} alt={logo.alt} />
          </S.LogoWrapper>
        ))}
      </Slider>
    </S.CarouselContainer>
  );
}

export default CompanyCarousel;
