import React, {useEffect, useContext} from "react";

import Header from "../../../components/header";
import Questions from "../../../components/questions";
import CompanyCarousel from "../../../components/carousel";
import GetInTouch from "../../../components/getInTouch";
/*import Servicess from "../../../components/Servicess";*/
import Footer from "../../../components/footer";
import carbonImage from "../../../assets/images/solutions/ImageSolutions.svg";
import InstallationServicess from "../../../components/Servicess/installationServices";
import Breadcrumbs from "../../../components/breadcrumbs";
import * as S from "./styles";
import {GlobalContext} from "../../../context/global/globalContext";

const questionsData = [
  {
    question: "What technologies do you use for emission reduction?",
    answer:
      "<span style=\"font-family: 'Lato', sans-serif\">We utilize a range of technologies including advanced scrubbers, MECS® mist eliminators, mesh pads, activated carbon filters.</span>",
  },
  {
    question: "How can emission reduction solutions benefit my business?",
    answer:
      "<span style=\"font-family: 'Lato', sans-serif\">Our emission control systems help you meet environmental regulations, reduce pollutant output, and improve operational efficiency.</span>",
  },
];

function InstallationService() {
  const {setBreadcrumbs} = useContext(GlobalContext);

  useEffect(() => {
    setBreadcrumbs([
      {label: "Home", path: "/"},
      {label: "Services", path: "/services"},
      {label: "Installation Services", path: "/services/installation"},
    ]);
  }, [setBreadcrumbs]);

  return (
    <>
      <Header />
      <S.PageContainer>
        <Breadcrumbs />

        <S.SectionTitle>Installation Services</S.SectionTitle>
        <S.Paragraph>
          We provide installation supervision for all Ecosystems Europe and MECS equipment, led by
          highly experienced engineers in industrial environments. Whether integrated into the
          equipment supply or arranged as a standalone service, our installation support ensures
          precision, safety, and efficiency. For Turnarounds (TAs), we offer complete manpower
          solutions—covering catalyst handling, mist eliminator replacement, mesh pad installation,
          and more.
        </S.Paragraph>

        <S.TwoColumnSection>
          <S.Column>
            <S.SectionTitle>Applications</S.SectionTitle>
            <S.List>
              <S.ListItem>Mist eliminator installation & replacement</S.ListItem>
              <S.ListItem>Catalyst screening & loading</S.ListItem>
              <S.ListItem>Scrubber and equipment installation</S.ListItem>
              <S.ListItem>Activated carbon exchange</S.ListItem>
              <S.ListItem>Fan, pump & ductwork installation</S.ListItem>
            </S.List>
          </S.Column>
          <S.ImageContainer>
            <img src={carbonImage} alt="Carbon Scrubber Equipment" />
          </S.ImageContainer>
        </S.TwoColumnSection>

        <S.SectionTitle>What we offer</S.SectionTitle>
        <S.Paragraph>
          We offer installation support, including professional supervision, skilled workforce for
          mounting and welding, and the preparation of detailed work methods and safety plans to
          ensure smooth and secure project execution.
        </S.Paragraph>

        <S.CenteredSection>
          <InstallationServicess />
        </S.CenteredSection>

        <S.SpacedBlock>
          <Questions items={questionsData} />
        </S.SpacedBlock>

        <S.Word>Our Services</S.Word>
        <S.Title>
          We partner with industry leaders to create clean alternatives to <br />
          traditional industrial processes
        </S.Title>

        <S.FullWidthSection>
          <CompanyCarousel />
        </S.FullWidthSection>
        <S.FullWidthSection>
          <GetInTouch />
        </S.FullWidthSection>
      </S.PageContainer>
      <Footer />
    </>
  );
}

export default InstallationService;
