import React from "react";

import FilterCard from "../../components/filterCard";
import Footer from "../../components/footer";
import GetInTouch from "../../components/getInTouch";
import Header from "../../components/header";
import * as S from "./styles";

function Projects() {
  return (
    <>
      <Header />
      <S.PageContainer>
        <S.MainHeading>Projects</S.MainHeading>
        <S.Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris rhoncus enim a leo
          feugiat, non interdum sem condimentum. Quisque blandit lorem quis felis pellentesque
          luctus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac
          turpis egestas. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere
          cubilia curae; Proin lacinia nunc nisl, blandit fermentum quam rutrum sit amet. Fusce ut
          porta libero. Maecenas scelerisque eros id sem gravida faucibus. Orci varius natoque
          penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse at nisl
          dignissim, dictum erat semper, feugiat nulla. Mauris semper leo at nulla cursus venenatis.
          Pellentesque fermentum, lectus sed egestas consequat, lectus dui iaculis orci, ac
          imperdiet ligula mauris et turpis. In congue iaculis pretium. In ac diam aliquet,
          vestibulum mi non, finibus orci.
        </S.Paragraph>

        <S.FiltersContainer>
          <S.FilterButton>All</S.FilterButton>
          <S.FilterButton>Emission Reduction</S.FilterButton>
          <S.FilterButton>Waste Treatment</S.FilterButton>
          <S.FilterButton>Heat Recovery</S.FilterButton>
          <S.FilterButton>Air Solutions</S.FilterButton>
          <S.FilterButton>Chemical Control</S.FilterButton>
          <S.FilterButton>Activated Carbon</S.FilterButton>
          <S.FilterButton>Activated Carbon</S.FilterButton>
          <S.FilterButton>Activated Carbon</S.FilterButton>
          <S.FilterButton>Activated Carbon</S.FilterButton>
          <S.FilterButton>Activated Carbon</S.FilterButton>
          <S.FilterButton>Activated Carbon</S.FilterButton>
          <S.FilterButton>Activated Carbon</S.FilterButton>
        </S.FiltersContainer>

        <FilterCard />
        <FilterCard />
        <FilterCard />
        <FilterCard />

        <S.LoadMoreButton>Load more projects</S.LoadMoreButton>
      </S.PageContainer>

      <GetInTouch />
      <Footer />
    </>
  );
}

export default Projects;
