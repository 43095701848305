import IconOil from "../../assets/images/industries/IconOil.svg";
import Button from "../button";
import * as S from "./styles";

const cardData = Array(12).fill({
  title: "Metals, Iron and Steel",
  link: "See projects",
  icon: IconOil,
});

function MiniCards() {
  return (
    <S.Wrapper>
      <S.Grid>
        {cardData.map((card, index) => (
          <S.Card key={index}>
            <S.Icon src={card.icon} alt={card.title} />
            <S.CardTitle>{card.title}</S.CardTitle>
            <S.Link href="#">{card.link}</S.Link>
          </S.Card>
        ))}
      </S.Grid>
      <S.ContactContainer>
        <S.ContactText>Want more information?</S.ContactText>
        <Button text="Contact us" href="/contact" />
      </S.ContactContainer>
    </S.Wrapper>
  );
}

export default MiniCards;
