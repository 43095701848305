import React from "react";

import Icon1 from "../../../assets/images/construction.png";
import Icon3 from "../../../assets/images/engineering.png";
import Icon4 from "../../../assets/images/science_off.png";
import * as S from "./styles";

function InstallationServicess() {
  return (
    <S.Service>
      <S.Title>Benefits and Advantages</S.Title>
      <S.CardServiceContainer>
        <S.CardService
          icon={Icon1}
          title="Efficiency"
          description="One supplier for delivery and installation ensures fast, error-free execution."
        />
        <S.CardService
          icon={Icon3}
          title="Cost-Effectiveness"
          description="Our installation managers are involved from day one, ensuring a smooth handover and cost-effective execution"
        />
        <S.CardService
          icon={Icon4}
          title="Expertise"
          description="Our engineers know this equipment inside out — making them the perfect team to handle installation efficiently and reliably."
        />
      </S.CardServiceContainer>
    </S.Service>
  );
}

export default InstallationServicess;
