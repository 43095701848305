import {useContext, useEffect} from "react";
import {useLocation} from "react-router-dom";

import solutionImage from "../../../assets/images/solutions/ImageSolutions.svg";
import Breadcrumbs from "../../../components/breadcrumbs";
import CardTechnology from "../../../components/cardTechnology";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import {GlobalContext} from "../../../context/global/globalContext";
import Questions from "../../../components/questions";
import Carousel from "../../../components/carousel";
import GetInTouch from "../../../components/getInTouch";
import * as S from "./styles";

function Desulphurisation() {
  const {setBreadcrumbs} = useContext(GlobalContext);
  const location = useLocation();

  useEffect(() => {
    setBreadcrumbs([
      {label: "Home", path: "/"},
      {label: "Solutions", path: "/solutions"},
      {label: "Desulphurisation", path: location.pathname},
    ]);
  }, [location, setBreadcrumbs]);

  const solutionsData = [
    {
      id: "ActivatedCarbon",
      title: "Activated Carbon",
      description: "Removes impurities from gases.",
    },
    {
      id: "MistEliminators",
      title: "Mist Eliminators",
      description: "Captures mist and fine droplets.",
    },
    {
      id: "GasScrubbers",
      title: "Gas Scrubbers",
      description: "Reduces pollutants in industrial exhaust.",
    },
    {
      id: "Dewpoint",
      title: "Dewpoint",
      description: "Measures moisture levels accurately.",
    },
  ];

  const questionsData = [
    {
      question: "What technologies do you use for emission reduction?",
      answer:
        "<span style=\"font-family: 'Lato', sans-serif\">We utilize a range of technologies including advanced scrubbers, MECS® mist eliminators, mesh pads, activated carbon filters.</span>",
    },
    {
      question: "How can emission reduction solutions benefit my business?",
      answer:
        "<span style=\"font-family: 'Lato', sans-serif\">Our emission control systems help you meet environmental regulations, reduce pollutant output, and improve operational efficiency.</span>",
    },
  ];
  return (
    <>
      <Header />
      <S.Container>
        <Breadcrumbs />
        <S.MainHeading>Desulphurisation</S.MainHeading>
        <S.Paragraph>
          Stricter emission limits are being introduced for H₂S, SO₂, and SO₃ presents growing
          challenges across industries, from chemical production to waste processing and metal
          refining. These sulphur compounds are not only corrosive and odorous but also harmful to
          human health and the environment.Effective tail gas scrubbing technologies are essential
          for achieving compliance, protecting infrastructure, and minimizing operational risks.
          Ecosystems offers advanced systems that utilize alkaline reagents such as ammonia or
          hydrogen peroxide to ensure high-efficiency sulphur capture.In line with sustainable
          engineering principles, our systems are designed to reduce liquid waste generation and
          maximize resource recovery. Wherever feasible, valuable sulphur components are converted
          into reusable products, supporting circular economy goals and reducing disposal costs.
        </S.Paragraph>

        <S.Section>
          <S.SectionTitle>Regulations and Safety</S.SectionTitle>
          <S.SubHeading>Environmental and Regulatory Standards</S.SubHeading>
          <S.Paragraph>
            Emission control is governed by EU directives aiming to significantly reduce
            sulphur-based pollutants. Industrial Emissions Directive (IED) — 2010/75/EU NEC
            Directive — 2016/2284/EU — targets 59% reduction by 2030 compared to 2005 levels BAT-AEL
            for sulphuric acid plants: SO₂: typically &lt 50 mg/Nm³ @ 11% O₂
          </S.Paragraph>
          <S.SubHeading>Health and Safety</S.SubHeading>
          <S.Paragraph>
            Controlling sulphur-based emissions is not only an environmental priority but also a
            critical step in protecting human health and equipment integrity. H₂S emissions from
            sources such as coke ovens, biogas plants, and waste processing are both toxic and
            odorous, requiring immediate and efficient treatment. SO₃ emissions pose a unique
            threat. Once in contact with atmospheric moisture, SO₃ converts to sulphuric acid
            droplets, which can corrode surfaces and harm respiratory health. High-efficiency mist
            eliminators are key to preventing these fine aerosols from escaping.
          </S.Paragraph>
        </S.Section>

        <S.ApplicationsSection>
          <S.ApplicationsList>
            <S.SectionTitle>Applications</S.SectionTitle>
            <S.List>
              <S.ListItem>
                Scrubbers with high-efficiency mist eliminators to remove sulphuric acid droplets
                from gas streams
              </S.ListItem>
              <S.ListItem>
                Tail gas scrubbers in sulphuric acid plants remove SO₃ and NH₃, recovering valuable
                byproducts.
              </S.ListItem>
              <S.ListItem>
                Metal off-gases containing SO₂ and dust are treated using scrubbers, reducing
                emissions and odours
              </S.ListItem>
            </S.List>
          </S.ApplicationsList>
          <S.ImageContainer>
            <img src={solutionImage} alt="Worker next to a flue gas desulfurization equipment" />
          </S.ImageContainer>
        </S.ApplicationsSection>

        <S.TwoColumnSection>
          <S.Column>
            <S.SectionTitle>Process Design</S.SectionTitle>
            <S.List>
              <S.ListItem>Temperature Range: 5 to 85 °C </S.ListItem>
              <S.ListItem>Flow Rates: Designed for 500 to 100,000 Nm³/h,</S.ListItem>
              <S.ListItem>Operating Pressure: -50 to 150 mbar,</S.ListItem>
              <S.ListItem>Pressure Drop: 10–25 mbar</S.ListItem>
              <S.ListItem>Efficiency: up to 99,9% removal efficiency</S.ListItem>
            </S.List>
          </S.Column>
          <S.Column>
            <S.SectionTitle>Materials</S.SectionTitle>
            <S.List>
              <S.ListItem>Special alloys: Hastelloy, Zecor</S.ListItem>
              <S.ListItem>Stainless steel (316L, 304L, 904L)</S.ListItem>
              <S.ListItem>FRP, HDPE, and PP</S.ListItem>
              <S.ListItem>Linings of PP or PTFE in acidic enviroments</S.ListItem>
            </S.List>
          </S.Column>
        </S.TwoColumnSection>
      </S.Container>
      <CardTechnology data={solutionsData} />
      <Questions items={questionsData} />
      <Carousel />
      <GetInTouch />
      <Footer />
    </>
  );
}

export default Desulphurisation;
