import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import {useRef} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import * as S from "./styles";

const PhotoGallery = ({images}) => {
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <S.GalleryContainer>
      <S.ArrowButton left onClick={prevSlide}>
        <LeftOutlined />
      </S.ArrowButton>

      <Slider ref={sliderRef} {...settings}>
        {images.map((image, index) => (
          <S.PhotoCard key={index}>
            <S.Photo src={image.src} alt={image.alt} />
            <S.PhotoDescription>{image.description}</S.PhotoDescription>
          </S.PhotoCard>
        ))}
      </Slider>

      <S.ArrowButton right onClick={nextSlide}>
        <RightOutlined />
      </S.ArrowButton>
    </S.GalleryContainer>
  );
};

export default PhotoGallery;
