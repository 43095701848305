import {UpOutlined as Arrow, DownOutlined as ArrowOutlined} from "@ant-design/icons";
import styled from "styled-components";

import fonts from "../../utils/fonts";
import {device} from "../../utils/mediaQueries";

export const Container = styled.div`
  background-color: ${({theme}) => theme.colors.lightBlue};
  border-radius: 12px;
  padding: 32px;
  color: ${({theme}) => theme.colors.white};
  max-width: 800px;
  margin: 0 auto;

  @media ${device.tablet} {
    padding: 24px;
  }

  @media ${device.mobile} {
    padding: 16px;
  }
`;

export const Title = styled.h2`
  ${fonts.lato.headingH4}
  margin-bottom: 24px;

  @media ${device.mobile} {
    font-size: 18px;
  }
`;

export const QuestionBlock = styled.div`
  ${fonts.lato.headingH5}
  border-bottom: 1px solid #ffffff40;
  padding: 12px 0;
`;

export const QuestionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const QuestionText = styled.h3`
  font-size: 16px;
  font-weight: 500;

  @media ${device.mobile} {
    font-size: 14px;
  }
`;

export const ToggleIcon = styled.span`
  font-size: 14px;
`;

export const Answer = styled.div`
  ${fonts.lato.paragraph}

  margin-top: 8px;
  font-size: 14px;
  line-height: 1.4;

  @media ${device.mobile} {
    font-size: 13px;
  }
`;

export const Footer = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: center;
`;

export const FooterText = styled.span`
  ${fonts.lato.paragraph}
`;

export const IconArrow = styled(Arrow)`
  font-size: 16px;
  color: ${({theme}) => theme.colors.white};
`;

export const IconDownArrow = styled(ArrowOutlined)`
  font-size: 16px;
  color: ${({theme}) => theme.colors.white};
`;
