import {Link} from "react-router-dom";
import styled from "styled-components";

import fonts from "../../utils/fonts";

export const BreadcrumbNav = styled.nav`
  ${fonts.lato.paragraphLight}
  margin-bottom: 60px;
`;

export const CrumbLink = styled(Link)`
  color: ${({theme}) => theme.colors.lightBlue};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const Separator = styled.span`
  margin: 0 6px;
  color: ${({theme}) => theme.colors.lightBlue};
`;

export const CrumbCurrent = styled.span`
  color: ${({theme}) => theme.colors.lightBlue};
  ${fonts.lato.paragraphBold}
`;
