import React from "react";

import carbonImage from "../../../assets/images/solutions/ImageSolutions.svg";
import CardTechnology from "../../../components/cardTechnology";
import CompanyCarousel from "../../../components/carousel";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import * as S from "./styles";
import GetInTouch from "../../../components/getInTouch";
import Questions from "../../../components/questions";

function Brink() {
  const mistData = [
    {
      id: "Filter",
      title: "Activated Wet Scrubbers",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris rhoncus enim a leo feugiat, non interdum sem condimentum. Quisque blandit lorem quis felis pellentesque luctus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Proin lacinia nunc nisl, blandit fermentum quam rutrum sit amet. Fusce ut porta libero. Maecenas scelerisque eros id sem gravida faucibus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse at nisl dignissim, dictum erat semper, feugiat nulla. Mauris semper leo at nulla cursus venenatis. ",
    },
    {
      id: "Filter",
      title: "Dry Scrubbers",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris rhoncus enim a leo feugiat, non interdum sem condimentum. Quisque blandit lorem quis felis pellentesque luctus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Proin lacinia nunc nisl, blandit fermentum quam rutrum sit amet. Fusce ut porta libero. Maecenas scelerisque eros id sem gravida faucibus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse at nisl dignissim, dictum erat semper, feugiat nulla. Mauris semper leo at nulla cursus venenatis. ",
    },
    {
      id: "Filter",
      title: "Eletroctatic Precipitators",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris rhoncus enim a leo feugiat, non interdum sem condimentum. Quisque blandit lorem quis felis pellentesque luctus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Proin lacinia nunc nisl, blandit fermentum quam rutrum sit amet. Fusce ut porta libero. Maecenas scelerisque eros id sem gravida faucibus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse at nisl dignissim, dictum erat semper, feugiat nulla. Mauris semper leo at nulla cursus venenatis. ",
    },
  ];
  const questionsData = [
    {
      question: "What technologies do you use for emission reduction?",
      answer:
        "<span style=\"font-family: 'Lato', sans-serif\">We utilize a range of technologies including advanced scrubbers, MECS® mist eliminators, mesh pads, activated carbon filters.</span>",
    },
    {
      question: "How can emission reduction solutions benefit my business?",
      answer:
        "<span style=\"font-family: 'Lato', sans-serif\">Our emission control systems help you meet environmental regulations, reduce pollutant output, and improve operational efficiency.</span>",
    },
  ];

  return (
    <>
      <Header />
      <S.Container>
        <S.MainHeading>Brink® Mist Eliminator</S.MainHeading>
        <S.Paragraph>
          Mist eliminators are high efficient filters designed to remove sub-micron liquid particles
          from gas streams in a wide range of industrial and chemical processes. With removal
          efficiencies of up to 99,95%, they are ideal for both coarse droplets (&lt15 µm) and
          sub-micron mist (&lt3 µm), ensuring clean gas flow and protection of downstream equipment.
          All of Ecosystems Europe mist eliminators are tailored to your specific application and
          operating conditions. Our range includes mist eliminators, Brink filter candles, mesh pad
          demisters, and vane or chevron droplet separators.
        </S.Paragraph>

        <S.TwoColumnSection>
          <S.Column>
            <S.SectionTitle>How Mist Eliminators work</S.SectionTitle>
            <S.Paragraph>
              Mist eliminators separate fine liquid particles—often submicron in size—from gas
              streams. As the gas passes through a dense fiber bed, droplets are captured by
              inertial impaction and Brownian motion. The liquid droplets merge into larger
              droplets, which then drain by gravity through a patented secondary layer, preventing
              re-entrainment.
              <br />
              <br />
              This process effectively removes acid mists, organic vapors, amines, and soluble
              particulates with efficiencies up to 99,95%.
              <br />
              <br />
              Mist eliminators are used across industries such as:
              <ul>
                <li>Chemical production (e.g., chlorine, nitric and phosphoric acid)</li>
                <li>Sulphuric acid plants</li>
                <li>Fertilizer manufacturing</li>
                <li>Compressed gas systems</li>
                <li>Polymer and textile processing</li>
                <li>Bitumen and asphalt plants</li>
              </ul>
              <br />
              They are low-maintenance, self-cleaning, and ideal for both standard and harsh
              environments.
            </S.Paragraph>
          </S.Column>
          <S.ImageContainer>
            <img src={carbonImage} alt="Carbon Scrubber Equipment" />
          </S.ImageContainer>
        </S.TwoColumnSection>

        <S.Section>
          <S.SectionTitle>Installation</S.SectionTitle>
          <S.Paragraph>
            Ecosystems Europe offers turnkey solutions based on mist eliminator technology, fully
            tailored to your process. We also provide supervised on-site installation to ensure
            proper setup and reliable performance.
          </S.Paragraph>
        </S.Section>

        <S.Section>
          <S.SectionTitle>Maintenance</S.SectionTitle>
          <S.Paragraph>
            Mist eliminators require minimal maintenance thanks to their self-cleaning design and
            durable construction. For fiber bed systems, repacking is available to reduce process
            downtime and costs.
          </S.Paragraph>
        </S.Section>

        <S.Section>
          <S.SectionTitle>Certifications and Compliance</S.SectionTitle>
          <S.Paragraph>
            All our mist eliminators are manufactured in accordance with European industrial
            standards. They are customizable for different chemical resistances, operating
            pressures, and safety certifications.
          </S.Paragraph>
        </S.Section>
        <CardTechnology gridList data={mistData} />
        <S.ContainerTitles>
          <S.MiniTitle>Our Partners</S.MiniTitle>
          <S.Title>
            We partner with industry leaders to create clean alternatives to traditional industrial
            processes
          </S.Title>
        </S.ContainerTitles>
        <Questions items={questionsData} />
        <CompanyCarousel />
        <GetInTouch />
      </S.Container>
      <Footer />
    </>
  );
}

export default Brink;
